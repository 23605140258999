import { useGraphqlClient } from "@/contexts/graphqlClientContext";
import { useGraphqlReactQueryUtils } from "@/contexts/useGraphqlReactQueryUtils";
import { useErrorHandling } from "@/helpers/useErrorHandling";
import {
  type ExtractFormDataType,
  FormBuilder,
} from "@/modules/form-builders/FormBuilder";
import { usePrincessAuthDialogContext } from "@/oldFeatures/tailwind/components/PrincessAuthDialogContext";
import { useUserForgetPasswordMutation } from "@princess/graphql-codegen/graphql-react-query";
import { Button } from "@/components/Button";
import { Text } from "@/modules/client/components/typography/Text";
import { useTranslation } from "next-i18next";
import { type ComponentProps, useCallback, useMemo, useState } from "react";
import { z } from "zod";
import Image from "next/image";

export const ForgetPasswordForm = () => {
  const { handleGraphqlError } = useErrorHandling();
  const { openAuthDialog, closeAuthDialog, setTitle } =
    usePrincessAuthDialogContext();
  const { t, i18n } = useTranslation("home");
  const [success, setSuccess] = useState(false);
  const { graphqlClient } = useGraphqlClient();
  const { context } = useGraphqlReactQueryUtils();
  const { mutateAsync: putUserForgetPassword } = useUserForgetPasswordMutation(
    graphqlClient,
    {
      context,
    },
  );

  const formFields = useMemo(
    () =>
      ({
        email: {
          label: t("password.email"),
          required: true,
          type: "email",
          placeholder: "",
          validation: z
            .string({
              required_error: t("password.email_required"),
            })
            .email({
              message: t("common:auth.invalid_email"),
            }),
        },
      }) satisfies ComponentProps<typeof FormBuilder>["fields"],
    [t],
  );

  const formComponents = useMemo<
    ComponentProps<typeof FormBuilder>["components"]
  >(
    () => ({
      subActionButton: (props) => <Button {...props} variant="destructive" />,
    }),
    [],
  );

  const onSubmit = useCallback(
    async (data: ExtractFormDataType<typeof formFields>) => {
      try {
        await putUserForgetPassword({
          userForgetPasswordInput: {
            email: data.email,
            lang: i18n.language,
          },
        });

        setTitle(t("password.reset_link_sent_title"));
        setSuccess(true);
      } catch (error: unknown) {
        if (error instanceof Error) {
          handleGraphqlError({ error });
        }
      }
    },
    [handleGraphqlError, i18n.language, putUserForgetPassword, setTitle, t],
  );

  const onCancel = useCallback(() => {
    openAuthDialog("email", "login");
  }, [openAuthDialog]);

  if (success) {
    return (
      <div className="flex flex-col items-center gap-5">
        <Image
          src="/assets/icons/email_sent.svg"
          width={140}
          height={112}
          alt="emailSent"
        />
        <Text>{t("password.reset_link_sent")}</Text>
        <Button className="w-full" onClick={() => closeAuthDialog()}>
          {t("password.close")}
        </Button>
      </div>
    );
  }

  return (
    <FormBuilder
      layout={["email"]}
      fields={formFields}
      onSubmit={onSubmit}
      actionButtonLabel={t("common:action.confirm")}
      onSubActionButtonClick={onCancel}
      subActionButtonLabel={t("common:action.back")}
      components={formComponents}
      footer={<Text>{t("password.forget_password_desc")}</Text>}
    />
  );
};
