import { isValidElement, type ComponentProps, type ReactNode } from "react";
import hotToast, { Toaster as HotToaster, ToastBar } from "react-hot-toast";
import {
  HiCheckCircle,
  HiExclamation,
  HiInformationCircle,
  HiOutlineX,
  HiXCircle,
} from "react-icons/hi";

import { cn } from "@/modules/external/shadcn/ui/lib/utils";

type HotToasterProps = ComponentProps<typeof HotToaster>;

type ToastParams = Parameters<typeof hotToast>;
type Message = ToastParams[0];
type ToastOptions = ToastParams[1] & {
  actionButton?: null | ((t: string) => ReactNode) | ReactNode;
};

function toast(message: Message, options?: ToastOptions) {
  const {
    actionButton = (id: string) => (
      <div
        className="h-8 w-8 cursor-pointer p-2 text-gray-400"
        onClick={() => hotToast.dismiss(id)}
      >
        <HiOutlineX />
      </div>
    ),
    icon,
    ...rest
  } = options ?? {};

  const className = cn(
    "flex w-full items-center justify-between text-white",
    rest.className,
  );

  if (typeof message === "function") {
    return hotToast(message);
  }

  function renderActionButton(id: string) {
    if (actionButton === null) return null;

    if (isValidElement(actionButton)) {
      return actionButton;
    }

    if (typeof actionButton === "function") return actionButton(id);
    return actionButton;
  }

  return hotToast((t) => (
    <div className={className}>
      <div className="flex items-center space-x-2 py-2">
        <span>{icon}</span>
        <span className="whitespace-pre-wrap">{message}</span>
      </div>

      <div className="-mr-2">{renderActionButton(t.id)}</div>
    </div>
  ));
}

toast.success = (message: Message, options?: ToastOptions) => {
  return toast(message, {
    ...options,
    icon: <HiCheckCircle className="h-6 w-6 text-green-400" />,
  });
};

toast.warn = (message: Message, options?: ToastOptions) => {
  return toast(message, {
    ...options,
    icon: <HiExclamation className="h-6 w-6 text-yellow-400" />,
  });
};

toast.error = (message: Message, options?: ToastOptions) => {
  return toast(message, {
    ...options,
    icon: <HiXCircle className="h-6 w-6 text-red-400" />,
  });
};

toast.info = (message: Message, options?: ToastOptions) => {
  return toast(message, {
    ...options,
    icon: <HiInformationCircle className="h-6 w-6 text-blue-400" />,
  });
};

toast.dismiss = hotToast.dismiss;

export { toast as default, toast };

export function Toaster(
  props: Omit<HotToasterProps, "containerStyle" | "containerClassName">,
) {
  const { position = "bottom-left", toastOptions = {}, ...restProps } = props;

  return (
    <HotToaster
      position={position}
      toastOptions={{
        ...toastOptions,
        className: cn(
          "min-w-72 flex w-full justify-start md:w-auto",
          toastOptions.className,
        ),
        style: { background: "#D69D43" },
      }}
      containerStyle={{ inset: 0 }}
      containerClassName="mx-5 my-5"
      {...restProps}
    >
      {(t) => (
        <ToastBar
          toast={t}
          style={{ maxWidth: "100%", background: "#D69D43" }}
        />
      )}
    </HotToaster>
  );
}
