import {
  ImageDisplay,
  type ImageDisplayProps,
} from "@/featuresPrincess/common/ImageDisplay";
import { type User as GraphqlUser } from "@princess/common/user/types";
import {
  UserStatusEnum as PrismaUserStatusEnum,
  type User as PrismaUser,
} from "@princess/db/prisma";
import { UserStatusEnum as GraphqlUserStatusEnum } from "@princess/graphql-codegen/graphql-react-query";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";

const AvatarPlaceholder = "/assets/images/profile_head.png";

type AvatarDisplayProps = {
  user:
    | Pick<
        GraphqlUser,
        "status" | "avatar" | "avatarBanned" | "userStatus" | "bannedUntil"
      >
    | Pick<
        PrismaUser,
        "status" | "avatar" | "avatarBanned" | "userStatus" | "bannedUntil"
      >;
  src?: string | null;
  showAvatarBannedOverlay?: boolean;
  placeholder?: string;
  className?: string;
} & Omit<ImageDisplayProps, "src" | "placeholder">;

export const AvatarDisplay = ({
  user,
  src,
  showAvatarBannedOverlay = true,
  placeholder,
  className,
  ...imageDisplayProps
}: AvatarDisplayProps) => {
  const isDeletedUser =
    user.status === GraphqlUserStatusEnum.Deleted ||
    user.userStatus === PrismaUserStatusEnum.DELETED;
  const isBannedUser =
    user.status === GraphqlUserStatusEnum.Banned ||
    user.userStatus === PrismaUserStatusEnum.BANNED;

  const shouldReplaceAvatarWithBannedOverlay =
    !isDeletedUser &&
    !isBannedUser &&
    user.avatarBanned &&
    showAvatarBannedOverlay;

  return (
    <div className={cn("relative", className)}>
      <ImageDisplay
        {...imageDisplayProps}
        src={
          isDeletedUser
            ? "/assets/images/deleted_user.jpg"
            : shouldReplaceAvatarWithBannedOverlay
              ? "/assets/images/banned_avatar_overlay.png"
              : (src ?? user.avatar ?? placeholder ?? AvatarPlaceholder)
        }
        containerClassName="relative overflow-clip rounded-full border border-gray-300"
      />

      {isBannedUser && (
        <ImageDisplay
          alt="banned"
          containerClassName="absolute inset-0"
          size={imageDisplayProps.size}
          shape={imageDisplayProps.shape}
          src={
            !!user?.bannedUntil
              ? "/assets/images/restricted_user_overlay.png"
              : "/assets/images/banned_user_overlay.png"
          }
        />
      )}
    </div>
  );
};
