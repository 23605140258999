import Link, { type LinkProps } from "next/link";
import { Checkbox } from "@/components/Checkbox";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";

export type DisclaimerProps = {
  className?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  renderMessage: ({ Link }: { Link: typeof ExternalLink }) => React.ReactNode;
};

export const Disclaimer: React.FC<DisclaimerProps> = (props) => {
  return (
    <div className={cn("flex items-center space-x-2", props.className)}>
      <Checkbox
        id="terms"
        checked={props.value}
        onCheckedChange={props.onChange}
      />
      <div className="grid gap-1.5 leading-none">
        <label
          htmlFor="terms"
          className="font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {props.renderMessage({ Link: ExternalLink })}
        </label>
      </div>
    </div>
  );
};

const ExternalLink: React.FC<LinkProps & { children: React.ReactNode }> = ({
  href,
  children,
}) => {
  return (
    <Link href={href} target="_blank" className="underline hover:opacity-70">
      {children}
    </Link>
  );
};
