import CreditIcon from "public/assets/icons/credit_gold.svg";
import FrdQuotaIcon from "public/assets/icons/frd_quota.svg";
import NonFrdMsgQuotaIcon from "public/assets/icons/non_frd_msg_quota.svg";
import { UserMembershipTag } from "@/features/user/components/UserMembershipTag";
import { HiChevronRight } from "react-icons/hi";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { Route } from "@/consts";
import { Text } from "@/modules/client/components/typography/Text";
import { RatingStars } from "@/features/common/RatingStars";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { replaceRouteWithParams } from "@/helpers/replaceRouteWithParams";
import { AvatarDisplay } from "@/features/user/components/AvatarDisplay";
import type { Myself } from "@princess/common/user/types";
import { type ImageDisplayProps } from "@/featuresPrincess/common/ImageDisplay";

type UserProfilePreviewProps = {
  user: Myself;
  className?: string;
  avatarSize?: ImageDisplayProps["size"];
};
export const UserProfilePreview = ({
  user,
  className,
  avatarSize = "userDropdown",
}: UserProfilePreviewProps) => {
  const router = useRouter();

  const userRate = Math.round(user?.rateSummary?.rate ?? 0);

  const navigateToProfile = useCallback(() => {
    router.push(
      replaceRouteWithParams(Route.UserProfile, { userId: user.userId }),
    );
  }, [router, user.userId]);

  return (
    <div
      className={cn(
        "flex w-full cursor-pointer items-center gap-4 bg-white px-5 py-6",
        className,
      )}
      onClick={navigateToProfile}
    >
      <AvatarDisplay
        user={user}
        alt={user.nickname ?? user.userId}
        shape="roundedFull"
        size={avatarSize}
      />

      <div className="flex flex-grow flex-col items-start gap-1.5">
        <div className="flex w-full items-center gap-1">
          <span className="text-lg text-gray-900">{user.nickname}</span>
          <HiChevronRight className="h-6 w-6 text-gray-500" />
        </div>

        <div className="flex items-center gap-3">
          <UserMembershipTag user={user as any} className="h-6" />
          <RatingStars value={userRate} readonly starClassName="h-3 w-3" />
        </div>

        <div className="flex gap-2 pt-1">
          {[
            { key: "credit", icon: CreditIcon },
            { key: "frdQuota", icon: FrdQuotaIcon },
            { key: "nonFriendMsgQuota", icon: NonFrdMsgQuotaIcon },
          ].map(({ key, icon }) => {
            const Icon = icon as any;
            return (
              <div
                className="flex items-center justify-center gap-1 border-r border-gray-100 pr-2 last:border-none"
                key={key}
              >
                <Icon className="h-4 w-4 text-gray-500" />
                <Text size="sm">{user[key] ?? 0}</Text>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
