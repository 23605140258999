import { cn } from "@/modules/external/shadcn/ui/lib/utils";

export const FormButton = ({
  variant,
  className,
  disabled,
  ...props
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { variant: "filled" | "outlined" }) => {
  const buttonClass =
    variant === "filled"
      ? "border-primary-500 bg-primary-500 hover:bg-opacity-90 text-white"
      : "border-primary-500 hover:bg-gray-300 hover:bg-opacity-30 text-primary-500";
  return (
    <button
      className={cn(
        "flex justify-center rounded-md border border-solid px-4 py-2",
        disabled &&
          (variant === "filled"
            ? "!border-gray-400 !bg-gray-400"
            : "!border-gray-200 !bg-gray-50 !text-gray-400"),
        buttonClass,
        className,
      )}
      disabled={disabled}
      {...props}
    />
  );
};
