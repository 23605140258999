import { InstallAppBadge } from "@/featuresPrincess/common/InstallAppBadge";
import { usePrincessAuthDialogContext } from "@/oldFeatures/tailwind/components/PrincessAuthDialogContext";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { Heading } from "@/modules/client/components/typography/Heading";
import { Text } from "@/modules/client/components/typography/Text";
import { useDomainContext } from "@/helpers/useDomainContext";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";

export const HomeIntroSection = () => {
  const { t } = useTranslation("home");
  const { openAuthDialog } = usePrincessAuthDialogContext();
  const { isMillionBB } = useDomainContext();
  return (
    <div className="relative flex h-[500px] max-w-full items-center md:h-[562px] lg:justify-start">
      {isMillionBB ? (
        <>
          <Image
            className="absolute inset-0 object-cover object-center lg:hidden"
            fill
            src="/assets/images/home_banner_millionbb.jpg"
            alt="MillionBB"
            // width={1903}
            // height={731}
            unoptimized
          />
          <Image
            className="absolute inset-0 hidden h-full w-full object-cover object-center lg:block"
            src="/assets/images/home_banner_millionbb.jpg"
            alt="MillionBB"
            width={1903}
            height={731}
            unoptimized
          />
        </>
      ) : (
        <div className="absolute inset-0">
          <Image
            fill
            className="object-cover object-center lg:hidden"
            src="/assets/images/home_banner_1_mobile.jpg"
            alt="Princess Dating"
          />
          <Image
            fill
            className="hidden object-cover object-center lg:block"
            src="/assets/images/home_banner_1.jpg"
            alt="Princess Dating"
          />
        </div>
      )}
      <div
        className={cn(
          "absolute inset-0 bg-gradient-to-r mix-blend-multiply",
          isMillionBB ? "" : "bg-gray-300",
        )}
      />
      <div className="relative mx-auto lg:ml-16">
        <Heading
          as="h1"
          className={cn(
            " text-center tracking-tight text-white sm:text-5xl",
            isMillionBB ? "textShadow-mbb" : "textShadow-sm",
          )}
        >
          {t("main.main_desc")}
        </Heading>
        <Text
          className={cn(
            "mx-4 mt-6 inline-block max-w-lg text-center text-xl text-white sm:mx-auto sm:max-w-3xl",
            isMillionBB ? "textShadow-mbb" : "textShadow-sm",
          )}
        >
          {t(
            isMillionBB ? `main.sub_desc_millionbb` : `main.sub_desc_princess`,
          )}
        </Text>

        <div className="mt-6 flex justify-center gap-4">
          <InstallAppBadge os="ios" />
          <InstallAppBadge os="android" />
        </div>

        <div className="mx-auto mt-10 flex max-w-sm justify-center gap-4">
          <button
            onClick={() => openAuthDialog("email", "login")}
            className="button primary transparent rounded-full"
          >
            {t("action.login")}
          </button>
          <button
            onClick={() => openAuthDialog("email", "register")}
            className="button primary filled rounded-full"
          >
            {t("action.register")}
          </button>
        </div>
      </div>
    </div>
  );
};
