"use client";

import { objectEntries } from "@/modules/universal/typescript/objectEntries";
import { ChevronDown, Nut } from "lucide-react";
import { useState } from "react";
import { MdError } from "react-icons/md";
import { FeedbackBanner } from "./FeedbackBanner";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";

export type ErrorDisplayProps = {
  className?: string;
  variant?: "alert" | "banner" | "view" | "page";
  error:
    | {
        name?: string;
        message: string;
        cause?: Error | string | { code: number };
        stack?: string;
      }
    | Error;
  details?: {
    source?: string;
    code?: string | number;
    traceId?: string;
  };
  icon?: React.ReactNode;
  action?: React.ReactNode;
};

export function ErrorDisplay({
  error,
  details,
  className,
  variant = "alert",
  action,
  icon = <MdError className="h-8 w-8 text-red-500" />,
}: ErrorDisplayProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasDetails = Boolean(details || error.cause || error.stack);

  if (variant === "banner") {
    return (
      <FeedbackBanner
        className={className}
        message={`${error.name} - ${error.message}`}
        variant="error"
      />
    );
  }

  const ErrorContent = () => (
    <>
      <div
        className={cn("flex", variant !== "alert" && "flex-col items-center")}
      >
        <div
          className={cn("flex-shrink-0", variant === "alert" ? "mr-2" : "mb-4")}
        >
          {icon}
        </div>
        <div
          className={cn(
            "flex-grow space-y-2",
            variant !== "alert" && "text-center",
          )}
        >
          <h3
            className={cn(
              "font-semibold tracking-tight",
              variant === "alert" ? "text-lg text-red-900" : "text-xl",
            )}
          >
            {error.name || "Error"}
          </h3>
          <p
            className={cn(
              "break-words",
              variant === "alert" ? "text-red-700" : "text-muted-foreground",
            )}
          >
            {error.message}
          </p>
        </div>
      </div>
      {action && <div className="mt-4">{action}</div>}
    </>
  );

  const ShowDetailsButton = () =>
    hasDetails && (
      <button
        className={cn(
          "mt-4 flex items-center space-x-2 focus:outline-none md:mt-0",
          variant === "alert"
            ? "text-red-700 hover:text-red-900"
            : "text-muted-foreground hover:text-foreground",
        )}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className="whitespace-nowrap text-sm">
          {isExpanded ? "Hide" : "Show"} Details
        </span>
        <ChevronDown
          className={`h-4 w-4 transition-transform ${isExpanded ? "rotate-180" : ""}`}
        />
      </button>
    );

  if (variant === "view" || variant === "page") {
    return (
      <div
        className={cn(
          variant === "page" &&
            "flex h-[calc(100vh-4rem)] items-center justify-center",
          className,
        )}
      >
        <div
          className={cn(
            "bg-background flex flex-col items-center justify-center space-y-4 rounded-lg p-8",
            variant === "view" ? "w-full" : "max-w-md",
          )}
        >
          <ErrorContent />
          <ShowDetailsButton />
          {isExpanded && (
            <ErrorDetails error={error} details={details} variant={variant} />
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn(
        "my-4 rounded-md border-l-4 border-red-500 bg-red-50 p-4",
        className,
      )}
    >
      <div className="flex flex-col md:flex-row md:items-center md:justify-between">
        <ErrorContent />
        <ShowDetailsButton />
      </div>
      {isExpanded && (
        <ErrorDetails error={error} details={details} variant={variant} />
      )}
    </div>
  );
}

function ErrorDetails({
  error,
  details,
  variant,
}: {
  error: ErrorDisplayProps["error"];
  details: ErrorDisplayProps["details"];
  variant: ErrorDisplayProps["variant"];
}) {
  return (
    <div
      className={cn(
        "mt-4",
        variant === "alert" ? "border-t border-red-200 pt-4" : "",
      )}
    >
      {details?.traceId && (
        <div className="flex items-center gap-2 text-sm">
          <Nut className="h-4 w-4" />
          <span>Trace ID: {details.traceId}</span>
        </div>
      )}
      {details?.code && (
        <div className="mt-2 flex items-center gap-2 text-sm">
          <CircleAlertIcon className="h-4 w-4" />
          <span>Error code: {details.code}</span>
        </div>
      )}
      {details?.source && (
        <div className="mt-2 flex items-center gap-2 text-sm">
          <SourceIcon className="h-4 w-4" />
          <span>Source: {details.source}</span>
        </div>
      )}
      {error.cause instanceof Error && (
        <div className="mt-4 rounded-lg border border-gray-200 p-4">
          <h4 className="font-medium">Cause:</h4>
          <pre
            className={cn(
              "mt-2 max-h-96 max-w-72 overflow-x-auto whitespace-pre-wrap break-words rounded-md p-4 text-sm",
              variant === "alert"
                ? "bg-red-100 text-red-900"
                : "bg-muted text-muted-foreground",
            )}
          >
            {serializeError(error.cause)}
          </pre>
        </div>
      )}
      {error.stack && (
        <div className="mt-4 rounded-lg border border-gray-200 p-4">
          <h4 className="font-medium">Stack Trace:</h4>
          <pre
            className={cn(
              "mt-2 max-h-96 max-w-72 overflow-x-auto whitespace-pre-wrap break-words rounded-md p-4 text-sm",
              variant === "alert"
                ? "bg-red-100 text-red-900"
                : "bg-muted text-muted-foreground",
            )}
          >
            {error.stack}
          </pre>
        </div>
      )}
    </div>
  );
}

function SourceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 3h7v7H3z" />
      <path d="M14 3h7v7h-7z" />
      <path d="M14 14h7v7h-7z" />
      <path d="M3 14h7v7H3z" />
    </svg>
  );
}

function CircleAlertIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <line x1="12" x2="12" y1="8" y2="12" />
      <line x1="12" x2="12.01" y1="16" y2="16" />
    </svg>
  );
}

function serializeError(error: unknown): string {
  if (error instanceof Error) {
    let serialized = `${error.name}: ${error.message}`;

    if (error.stack) {
      serialized += `\nStack: ${error.stack}`;
    }

    if ("cause" in error && error.cause) {
      serialized += `\nCause: ${serializeError(error.cause)}`;
    }

    // Serialize additional properties
    const additionalProps = objectEntries(error)
      .filter(([key]) => !["name", "message", "stack", "cause"].includes(key))
      .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
      .join(", ");

    if (additionalProps) {
      serialized += `\nAdditional properties: { ${additionalProps} }`;
    }

    return serialized;
  }

  if (typeof error === "object" && error !== null) {
    try {
      return JSON.stringify(error, null, 2);
    } catch {
      return `[Unserializable object]: ${Object.prototype.toString.call(error)}`;
    }
  }

  if (typeof error === "function") {
    return `[Function: ${error.name || "anonymous"}]`;
  }

  return String(error);
}
