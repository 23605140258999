import * as ShadcnSheet from "@/modules/external/shadcn/ui/components/ui/sheet";
import { type ComponentPropsWithoutRef, forwardRef } from "react";
import { twc } from "react-twc";
import * as SheetPrimitive from "@radix-ui/react-dialog";
import { cva } from "class-variance-authority";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { X } from "lucide-react";

export const Sheet = ShadcnSheet.Sheet;

export const SheetTrigger = ShadcnSheet.SheetTrigger;

export const SheetClose = ShadcnSheet.SheetClose;

export const SheetPortal = ShadcnSheet.SheetPortal;

export const SheetOverlay = twc(ShadcnSheet.SheetOverlay)`bg-black/60`;

const sheetVariants = cva(
  "bg-background data-[state=open]:animate-in data-[state=closed]:animate-out fixed z-50 gap-4 p-6 shadow-lg transition ease-in-out data-[state=closed]:duration-300 data-[state=open]:duration-500",
  {
    variants: {
      side: {
        top: "data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top inset-x-0 top-0 border-b",
        bottom:
          "data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom inset-x-0 bottom-0 border-t",
        left: "data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left inset-y-0 left-0 h-full w-3/4 border-r sm:max-w-sm",
        right:
          "data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right inset-y-0 right-0 h-full w-3/4 border-l sm:max-w-sm",
      },
    },
    defaultVariants: {
      side: "right",
    },
  },
);

export const SheetContent = forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  ComponentPropsWithoutRef<typeof ShadcnSheet.SheetContent> & {
    hideCloseButton?: boolean;
  }
>(({ side = "right", className, children, hideCloseButton, ...props }, ref) => (
  <SheetPortal>
    <SheetOverlay />
    <SheetPrimitive.Content
      ref={ref}
      className={cn(sheetVariants({ side }), className)}
      {...props}
    >
      {!hideCloseButton && (
        <SheetPrimitive.Close className="ring-offset-background focus:ring-ring data-[state=open]:bg-secondary absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none">
          <X className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </SheetPrimitive.Close>
      )}
      {children}
    </SheetPrimitive.Content>
  </SheetPortal>
));

export const SheetHeader = ShadcnSheet.SheetHeader;

export const SheetFooter = ShadcnSheet.SheetFooter;

export const SheetTitle = ShadcnSheet.SheetTitle;

export const SheetDescription = ShadcnSheet.SheetDescription;
