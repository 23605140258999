import { Route } from "@/consts/Route";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { Text } from "@/modules/client/components/typography/Text";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";

export const TncAndPrivacyLinks = ({
  textClassName,
}: {
  textClassName?: string;
}) => {
  const { t } = useTranslation("common");
  const sharedClassName = cn("text-sm text-gray-500", textClassName);

  return (
    <div className="inline text-gray-500">
      <Link
        className="underline hover:text-gray-700"
        href={Route.Tnc}
        rel="noreferrer noopener"
        target="_blank"
      >
        <Text className={cn("hover:text-gray-700", sharedClassName)}>
          {t("tnc_privacy.tnc")}
        </Text>
      </Link>
      <Text className={sharedClassName}>{t("and")}</Text>
      <Link
        className="underline hover:text-gray-700"
        href={Route.Privacy}
        rel="noreferrer noopener"
        target="_blank"
      >
        <Text className={cn("hover:text-gray-700", sharedClassName)}>
          {t("tnc_privacy.privacy")}
        </Text>
      </Link>
    </div>
  );
};
