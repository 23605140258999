import { useGiftCollection } from "@/features/user/hooks/useGiftCollection";
import { getPublicConfig } from "@/helpers/getPublicConfig";

import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { Button } from "@/components/Button";
import { Text } from "@/modules/client/components/typography/Text";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/Dialog";
import { Skeleton } from "@/components/Skeleton";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { type ComponentProps, Fragment, useState, useCallback } from "react";

const {
  features: { monthlyGift },
} = getPublicConfig();

type MonthlyGiftCollectionSectionProps = {
  onCollectSuccess: () => void;
};
export const MonthlyGiftCollectionSection = ({
  onCollectSuccess,
}: MonthlyGiftCollectionSectionProps) => {
  const { t } = useTranslation("user");
  const [isOpen, setIsOpen] = useState(false);
  const onSuccess = useCallback(() => {
    setIsOpen(true);
    onCollectSuccess();
  }, [onCollectSuccess]);
  const {
    hasCollected,
    isFetchingHasCollected,
    collectGift,
    isCollectingGift,
  } = useGiftCollection({ onSuccess });

  const closeDialog = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <Fragment>
      <div className="flex w-80 max-w-full items-center justify-between rounded-lg border border-[#FFE2CC] bg-[#FFF8E4] px-5 py-2">
        <div className="flex items-center gap-2.5">
          <Image
            src="/assets/images/gift.png"
            width={30}
            height={28}
            alt="gift_icon"
          />

          <div className="flex flex-col text-left">
            <Text size="sm" weight="medium" className="text-[#F5622F]">
              {t("monthly_gift.desc_1")}
            </Text>
            <Text size="sm" className="text-[#998376]">
              {t("monthly_gift.desc_2")}
            </Text>
          </div>
        </div>

        {isFetchingHasCollected ? (
          <Skeleton className="h-7 w-14" />
        ) : (
          <Button
            className={cn(
              "h-7 rounded-full bg-gradient-to-r from-[#FF715C] to-[#FE2B38] px-2.5 text-xs",
              (isCollectingGift || hasCollected) && "opacity-50",
              !hasCollected && "hover:opacity-70",
            )}
            loading={isCollectingGift}
            disabled={hasCollected}
            onClick={collectGift}
          >
            {t(
              hasCollected ? "monthly_gift.collected" : "monthly_gift.collect",
            )}
          </Button>
        )}
      </div>
      <CollectedGiftDialog
        open={isOpen}
        onOpenChange={setIsOpen}
        onClose={closeDialog}
      />
    </Fragment>
  );
};

type CollectedGiftDialogProps = ComponentProps<typeof Dialog> & {
  onClose: () => void;
};

const CollectedGiftDialog = (props: CollectedGiftDialogProps) => {
  const { t } = useTranslation("user");

  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("monthly_gift.collect_success_title")}</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col items-center justify-center gap-3.5">
          <Image
            src="/assets/images/gift_open.png"
            width={125}
            height={112}
            alt="gift_icon"
          />

          <Text weight="medium" className="block">
            {t("monthly_gift.collect_success_desc_1")}
          </Text>
          <Text>
            {t("monthly_gift.collect_success_amount", {
              amount: monthlyGift.nfmqAmount,
            })}
          </Text>

          <div>
            <Text as="p" className="text-gray-400" size="xs">
              {t("monthly_gift.reminder_1")}
            </Text>
            <Text as="p" className="text-gray-400" size="xs">
              {t("monthly_gift.reminder_2")}
            </Text>
          </div>
        </div>

        <Button className="w-full" onClick={props.onClose}>
          {t("common:ok")}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
