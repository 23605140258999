import { usePrincessAuthDialogContext } from "@/oldFeatures/tailwind/components/PrincessAuthDialogContext";
import { Heading } from "@/modules/client/components/typography/Heading";
import { Text } from "@/modules/client/components/typography/Text";
import { useTranslation } from "next-i18next";
import Image from "next/image";

export const StillThinkingSection = () => {
  const { t } = useTranslation("home");
  const { openAuthDialog } = usePrincessAuthDialogContext();
  return (
    <div className="relative">
      <div className="mx-auto max-w-full">
        <div className="relative">
          <div className="absolute inset-0 block md:hidden">
            <Image
              fill
              className="object-cover object-center"
              src="/assets/images/still_thinking_banner_mobile.png"
              alt={t("section.thinking.title")}
            />
          </div>
          <div className="absolute inset-0 hidden md:block">
            <Image
              fill
              className="object-cover object-center"
              src="/assets/images/still_thinking_banner_desktop.png"
              alt={t("section.thinking.title")}
            />
          </div>
          <div className="absolute inset-0 bg-gray-500 bg-gradient-to-r mix-blend-multiply" />
          <div className="relative px-4 py-8 text-center sm:px-6 sm:py-10 lg:px-8 lg:py-16">
            <Heading
              as="h3"
              className="text-center font-extrabold tracking-tight text-white drop-shadow-md"
            >
              {t("section.thinking.title")}
            </Heading>
            <Text className="mt-3 inline-block max-w-lg text-white drop-shadow-md sm:max-w-3xl">
              {t("section.thinking.description")}
            </Text>
            <div className="mx-auto mt-5 flex max-w-sm justify-center sm:mt-10">
              <div className="mx-auto inline-grid grid-cols-2 gap-5">
                <button
                  onClick={() => openAuthDialog("email", "login")}
                  className="button primary transparent rounded-full"
                >
                  {t("action.login")}
                </button>
                <button
                  onClick={() => openAuthDialog("email", "register")}
                  className="button primary filled rounded-full"
                >
                  {t("action.register")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
