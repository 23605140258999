import { IoNotificationsOutline } from "react-icons/io5";
import { IoNotifications } from "react-icons/io5";
import { isValidElement, type PropsWithChildren, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/Popover";
import { Heading } from "@/modules/client/components/typography/Heading";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";

type NotificationPopoverProps = PropsWithChildren<{
  title?: string | React.ReactNode;
  unreadNotificationCount: number;
  classes?: {
    closedIcon?: string;
    openedIcon?: string;
    unreadDot?: string;
    content?: string;
  };
  contentProps?: React.ComponentPropsWithoutRef<typeof PopoverContent>;
}>;
export const NotificationPopover = ({
  title,
  unreadNotificationCount,
  classes,
  contentProps,
  children,
}: NotificationPopoverProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger className="relative">
        {isPopoverOpen ? (
          <IoNotifications
            className={cn("h-6 w-6 text-gray-700", classes?.openedIcon)}
          />
        ) : (
          <IoNotificationsOutline
            className={cn("h-6 w-6 text-gray-700", classes?.closedIcon)}
          />
        )}

        {!isPopoverOpen && unreadNotificationCount > 0 && (
          <div
            className={cn(
              "bg-primary absolute right-1 top-1 h-2 w-2 rounded-full",
              classes?.unreadDot,
            )}
          />
        )}
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          "mx-auto max-h-80 w-[calc(100vw-32px)] overflow-scroll rounded-xl p-0 md:max-w-sm",
          classes?.content,
        )}
        align="end"
        collisionPadding={16}
        sideOffset={10}
        {...contentProps}
      >
        <div className="border-b border-b-gray-200 px-4 py-2.5">
          {isValidElement(title) ? (
            title
          ) : (
            <Heading as="h5" size="xs" variant="dark" weight="medium">
              {title ?? "通知"}
            </Heading>
          )}
        </div>

        <div className="space-y-4 p-4">{children}</div>
      </PopoverContent>
    </Popover>
  );
};
