import {
  differenceInDays,
  differenceInSeconds,
  format,
  formatDistanceStrict,
  isThisWeek,
  isToday,
  isYesterday,
  differenceInMonths,
  type Locale,
} from "date-fns";
import { enUS, zhHK } from "date-fns/locale";

export const dateFnsLocaleByLang: Record<"en" | "zh", Locale> = {
  en: enUS,
  zh: zhHK,
};

export const dateTimeFormatByLocale: Record<"en" | "zh", string> = {
  en: "h:mm a",
  zh: "ah:mm",
};

type TimeLabel = {
  justNow?: string;
  today?: string;
  yesterday?: string;
};

type DateFormat = {
  base?: string;
  today?: string;
  yesterday?: string;
  // default "EEEE"
  thisWeek?: string;
  // suggested "LLLdo E"
  lastWeek?: string;
  halfYear?: string;
};

export type FormatDateTimeOptions = {
  locale?: Locale;
  dateFormats?: DateFormat;
  ago?: boolean;
  labels?: TimeLabel;
};

export function formatDatetime(
  date: Date | number | undefined,
  options?: FormatDateTimeOptions,
) {
  if (!date) return "";

  const { ago, locale = zhHK, dateFormats, labels } = options ?? {};

  const {
    base = "d/M/yyyy",
    today: todayFormat,
    yesterday: yesterdayFormat,
    thisWeek: thisWeekFormat,
    lastWeek: lastWeekFormat,
    halfYear: halfYearFormat,
  } = dateFormats ?? {};
  if (!ago) return format(date, base, { locale });

  const { justNow, today, yesterday } = labels ?? {};

  if (justNow) {
    const secondsFromNow = differenceInSeconds(new Date(), date);
    const withinMinute = secondsFromNow < 60;
    if (withinMinute) return justNow;
  }

  // 5 分鐘前/ 5 小時前
  if (isToday(date)) {
    if (today) {
      return today;
    }
    if (todayFormat) {
      return format(date, todayFormat, { locale });
    }
    return formatDistanceStrict(date, new Date(), {
      locale,
      addSuffix: true,
    });
  }

  if (isYesterday(date)) {
    if (yesterday) return yesterday;

    if (yesterdayFormat) {
      return format(date, yesterdayFormat, { locale });
    }
  }

  // 星期六
  if (thisWeekFormat) {
    if (isThisWeek(date)) return format(date, thisWeekFormat, { locale });
  }

  // 10月8日 週日
  if (lastWeekFormat) {
    const isLastWeek = differenceInDays(new Date(), date) < 14;
    if (isLastWeek) return format(date, lastWeekFormat, { locale: zhHK });
  }

  if (halfYearFormat) {
    const withinHalfYear = differenceInMonths(new Date(), date) < 6;
    if (withinHalfYear) return format(date, halfYearFormat, { locale });
  }

  // 2023年4月13日
  return format(date, base, { locale });
}
