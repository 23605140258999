import { EmptyNotificationListDisplay } from "@/modules/features/notification/components/EmptyNotificationListDisplay";
import { NotificationListSkeletons } from "@/modules/features/notification/components/NotificationListSkeletons";
import { useInView } from "react-intersection-observer";
import { ErrorDisplay } from "@/modules/client/components/feedback/ErrorDisplay";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";

type NotificationListProps<TNotification> = {
  notifications: TNotification[];
  renderNotification: (comment: TNotification, i: number) => React.ReactNode;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  error?: Error | null;
  errorDisplay?: React.ReactNode;
  emptyListDisplay?: React.ReactNode;
  skeletons?: React.ReactNode;
  classes?: {
    container?: string;
  };
};
export const NotificationList = <TNotification,>({
  notifications,
  renderNotification,
  isLoading,
  hasNextPage = false,
  isFetchingNextPage,
  fetchNextPage,
  error,
  errorDisplay,
  emptyListDisplay,
  skeletons,
  classes,
}: NotificationListProps<TNotification>) => {
  const [lastItemRef] = useInView({
    onChange: (inview) => {
      if (isLoading) return;

      if (inview && hasNextPage) fetchNextPage();
    },
  });

  if (isLoading) {
    return skeletons ?? <NotificationListSkeletons />;
  }

  if (error) {
    return errorDisplay ?? <ErrorDisplay variant="view" error={error} />;
  }

  if (notifications.length === 0) {
    return emptyListDisplay ?? <EmptyNotificationListDisplay />;
  }

  return (
    <div className={cn("space-y-3.5", classes?.container)}>
      {notifications.map((notification, i) => (
        <div
          key={i}
          ref={i === notifications.length - 1 ? lastItemRef : undefined}
        >
          {renderNotification(notification, i)}
        </div>
      ))}
      {isFetchingNextPage && (skeletons ?? <NotificationListSkeletons />)}
    </div>
  );
};
