import { z } from "zod";

export const projectConfigSchema = z.object({
  chat: z.object({
    messageQuotaPerMessage: z.object({
      freeMember: z.number(),
      paidMember: z.number(),
    }),
  }),
  userFriend: z.object({
    wineInCredit: z.number(),
    frdQuotaInCredit: z.number(),
  }),
  userProfile: z.object({
    editNicknameFeeInCredit: z.number(),
  }),
  album: z.object({
    exclusiveAlbumAccessDays: z.number(),
  }),
});

export type ProjectConfig = z.infer<typeof projectConfigSchema>;

export const defaultProjectConfig: ProjectConfig = {
  chat: {
    messageQuotaPerMessage: {
      freeMember: 3,
      paidMember: 1,
    },
  },
  userFriend: {
    wineInCredit: 1,
    frdQuotaInCredit: 20,
  },
  userProfile: {
    editNicknameFeeInCredit: 5,
  },
  album: {
    exclusiveAlbumAccessDays: 30,
  },
};
