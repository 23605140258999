import { SvgIconButton as WmaUiSvgIconButton } from "@/modules/client/components/inputs/button/IconButton";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { type VariantProps, cva } from "class-variance-authority";
import { forwardRef, type ComponentProps, type ForwardedRef } from "react";

const iconButtonVariants = cva("", {
  variants: {
    color: {
      default: "text-gray-500 hover:text-gray-700",
      dark: "text-gray-700 hover:text-gray-900",
      primary: "text-primary hover:text-primary-dark",
    },
  },
  defaultVariants: {
    color: "default",
  },
});
type SvgIconButtonProps = VariantProps<typeof iconButtonVariants> &
  ComponentProps<typeof WmaUiSvgIconButton>;
export const SvgIconButton = forwardRef(
  (
    { variant = "ghost", classes, color, ...props }: SvgIconButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <WmaUiSvgIconButton
        ref={ref}
        classes={{
          button: cn(
            iconButtonVariants({ color }),
            variant === "ghost" && "hover:bg-transparent",
            classes?.button,
          ),
          icon: classes?.icon,
        }}
        variant={variant}
        {...props}
      />
    );
  },
);
