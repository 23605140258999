import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { type ReactNode } from "react";

type UserHeaderBadgeProps = {
  children: ReactNode;
  className?: string;
};

export const UserHeaderTag = ({
  children,
  className,
}: UserHeaderBadgeProps) => {
  return (
    <div
      className={cn(
        "flex items-center rounded-full bg-gray-100 px-1.5 px-2 py-0.5 text-xs text-gray-500",
        className,
      )}
    >
      {children}
    </div>
  );
};
