"use client";

import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { Label } from "@/components/Label";
import { RadioGroup, RadioGroupItem } from "@/components/RadioGroup";
import React from "react";

export type Props = {
  options: {
    value: string;
    label: string;
    renderMeta?: () => JSX.Element;
  }[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  className?: string;
  classNames?: {
    label?: string;
  };
  itemsPerRow?: number;
};

export const SelectionGrid = ({
  options,
  value,
  onChange,
  disabled,
  classNames,
  className,
  itemsPerRow,
}: Props) => {
  const getClassForItemsPerRow = () => {
    const count = itemsPerRow ?? 0;

    if (count === 0) {
      return "";
    }

    if (count >= 3) {
      return "md:grid-cols-3";
    }

    if (count === 2) {
      return "md:grid-cols-2";
    }

    return "md:grid-cols-1";
  };
  return (
    <RadioGroup
      value={value}
      onValueChange={onChange}
      className={cn(
        "grid gap-2 overflow-x-auto p-1 md:grid-cols-3",
        getClassForItemsPerRow(),
        className,
      )}
      disabled={disabled}
    >
      {options.map((option) => (
        <div
          key={option.value}
          className={cn(
            "flex h-full min-w-[150px] flex-col items-start justify-between rounded-lg border p-4",
            value === option.value ? "ring-2 ring-black" : "",
            disabled ? "ring-gray-100" : "",
          )}
        >
          <Label
            className={cn(
              "flex w-full justify-between gap-8",
              option.renderMeta ? "" : "items-center",
              disabled ? "opacity-50" : "cursor-pointer",
            )}
            htmlFor={option.value}
          >
            <div className="min-w-[100px]">
              <div className={cn("text-lg font-semibold", classNames?.label)}>
                {option.label}
              </div>
              {option.renderMeta && <div>{option.renderMeta()}</div>}
            </div>
            <RadioGroupItem id={option.value} value={option.value} />
          </Label>
        </div>
      ))}
    </RadioGroup>
  );
};
