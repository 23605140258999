import { twc } from "react-twc";
import * as ShadcnForm from "@/modules/external/shadcn/ui/components/ui/form";

export const Form = ShadcnForm.Form;

export const FormControl = ShadcnForm.FormControl;

export const FormField = ShadcnForm.FormField;

export const FormLabel = twc(ShadcnForm.FormLabel)`leading-5`;

export const FormItem = twc(ShadcnForm.FormItem)`space-y-1`;

export const FormDescription = twc(ShadcnForm.FormDescription)``;

export const FormMessage = twc(ShadcnForm.FormMessage)`text-sm`;

export const useFormField = ShadcnForm.useFormField;
