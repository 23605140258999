import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { type PublicNavigationItemWithSubItems } from "@/features/common/hooks/usePublicNavigationItems";
import { useIsCurrentRoute } from "@/featuresPrincess/common/hooks/useIsCurrentRoute";

import {
  AccordionItem,
  AccordionContent,
  AccordionTrigger,
} from "@/components/Accordion";
import { NavLink } from "@/features/common/layout/header/NavLink";
type MobileNavAccordionItemProps = PublicNavigationItemWithSubItems & {
  onSubItemClick: () => void;
};
export const MobileNavAccordionItem = ({
  title,
  subItems,
  onSubItemClick,
}: MobileNavAccordionItemProps) => {
  const { isCurrentRoute } = useIsCurrentRoute();

  const isSubRouteActive = subItems.some(({ href }) => isCurrentRoute(href));

  return (
    <AccordionItem value={title} className="border-b-0">
      <AccordionTrigger
        className={cn(
          "px-5 py-2",
          isSubRouteActive ? "font-medium" : "font-normal",
        )}
      >
        {title}
      </AccordionTrigger>
      <AccordionContent className="ml-4 w-max pb-0">
        {subItems.map((subItem) => (
          <NavLink
            key={subItem.title}
            href={subItem.href}
            className={cn(
              "text-gray-500",
              isCurrentRoute(subItem.href) && "text-primary-500",
            )}
            onClick={onSubItemClick}
          >
            {subItem.title}
          </NavLink>
        ))}
      </AccordionContent>
    </AccordionItem>
  );
};
