import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { useState, type PropsWithChildren } from "react";

type NotificationItemContainerProps = PropsWithChildren<{
  classes?: {
    container?: string;
    unreadDot?: string;
  };
  unread?: boolean;
}>;
export const NotificationItemContainer = ({
  classes,
  unread = false,
  children,
}: NotificationItemContainerProps) => {
  // Lock the value in state to continues to show the dot while the popover is opened even if it's changed due to refetch
  const [showUnreadDot] = useState(unread);

  return (
    <div
      className={cn(
        "flex items-center justify-between gap-2",
        classes?.container,
      )}
    >
      {children}

      {showUnreadDot && (
        <div
          className={cn(
            "bg-primary mr-0 h-2 min-w-2 rounded-full",
            classes?.unreadDot,
          )}
        />
      )}
    </div>
  );
};
