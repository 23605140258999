import { DropdownMenuItem } from "@/components/DropdownMenu";
import { type UserMenuItem } from "@/featuresPrincess/layout/types";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import Link from "next/link";
import { UserProfilePreview } from "./UserProfilePreview";
import { UserDropdownMenuNavigationItem } from "./UserDropdownMenuItem";
import { MonthlyGiftCollectionSection } from "@/featuresPrincess/layout/userMenu/MonthlyGiftCollectionSection";
import { useGiftCollection } from "@/features/user/hooks/useGiftCollection";
import { useState } from "react";
import { SubscriptionOverview } from "@/features/user/components/SubscriptionOverview";

export const UserMenuContent = ({
  menuItems,
  closeMenu,
}: {
  menuItems: UserMenuItem[];
  closeMenu: () => void;
}) => {
  const { user } = useUserContext();
  const { shouldShowGiftCollection } = useGiftCollection();
  const [hasJustCollectedGift, setHasJustCollectedGift] = useState(false);

  if (!user) return null;

  return (
    <div className="relative flex max-h-full max-w-[375px] flex-1 flex-col gap-2.5 overflow-y-scroll bg-gray-50">
      <div className="space-y-3 bg-white p-5">
        <DropdownMenuItem className="p-0">
          <UserProfilePreview user={user} className="p-0" />
        </DropdownMenuItem>

        <SubscriptionOverview onActionClick={closeMenu} />

        {(hasJustCollectedGift || shouldShowGiftCollection) && (
          <MonthlyGiftCollectionSection
            onCollectSuccess={() => setHasJustCollectedGift(true)}
          />
        )}
      </div>
      <div className="flex flex-1 flex-col gap-1 bg-white px-2 py-4">
        {menuItems.map((item) => {
          if (item.href) {
            return (
              <DropdownMenuItem key={item.title} asChild>
                <Link
                  href={item.href}
                  passHref
                  className="w-full"
                  onClick={closeMenu}
                >
                  <UserDropdownMenuNavigationItem
                    title={item.title}
                    subtitle={
                      typeof item.subtitle === "function"
                        ? item.subtitle(closeMenu)
                        : item.subtitle
                    }
                    Icon={item.icon}
                  />
                </Link>
              </DropdownMenuItem>
            );
          }

          return (
            <DropdownMenuItem
              key={item.title}
              onClick={() => {
                item.onClick?.();
              }}
            >
              <UserDropdownMenuNavigationItem
                title={item.title}
                subtitle={
                  typeof item.subtitle === "function"
                    ? item.subtitle(closeMenu)
                    : item.subtitle
                }
                Icon={item.icon}
              />
            </DropdownMenuItem>
          );
        })}
      </div>
    </div>
  );
};
