import { toast } from "@/modules/client/components/feedback/toast";
import {
  HiCheckCircle,
  HiExclamation,
  HiXCircle,
  HiInformationCircle,
  HiOutlineX,
} from "react-icons/hi";
import hotToast from "react-hot-toast";
import { type ReactNode } from "react";
export * from "@/modules/client/components/feedback/toast";

type ToastParams = Parameters<typeof hotToast>;
type Message = ToastParams[0];
type ToastOptions = ToastParams[1] & {
  actionButton?: null | ((t: string) => ReactNode) | ReactNode;
};

const actionButton = (id: string) => (
  <div
    className="flex h-8 w-8 cursor-pointer items-center justify-center"
    onClick={() => hotToast.dismiss(id)}
  >
    <HiOutlineX className="h-6 w-6 text-white" />
  </div>
);

const commonToastOptions: ToastOptions = {
  actionButton,
  className: "font-medium",
};

toast.success = (message: Message, options?: ToastOptions) => {
  return toast(message, {
    ...commonToastOptions,
    ...options,
    icon: <HiCheckCircle className="h-6 w-6 text-white" />,
  });
};

toast.warn = (message: Message, options?: ToastOptions) => {
  return toast(message, {
    ...commonToastOptions,
    ...options,
    icon: <HiExclamation className="h-6 w-6 text-white" />,
  });
};

toast.error = (message: Message, options?: ToastOptions) => {
  return toast(message, {
    ...commonToastOptions,
    ...options,
    icon: <HiXCircle className="h-6 w-6 text-white" />,
  });
};

toast.info = (message: Message, options?: ToastOptions) => {
  return toast(message, {
    ...commonToastOptions,
    ...options,
    icon: <HiInformationCircle className="h-6 w-6 text-white" />,
  });
};

export default toast;
