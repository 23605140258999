import * as ShadcnDropdownMenu from "@/modules/external/shadcn/ui/components/ui/dropdown-menu";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { forwardRef } from "react";
import { twc } from "react-twc";

export const DropdownMenu = ShadcnDropdownMenu.DropdownMenu;

export const DropdownMenuTrigger = ShadcnDropdownMenu.DropdownMenuTrigger;

export const DropdownMenuGroup = ShadcnDropdownMenu.DropdownMenuGroup;

export const DropdownMenuPortal = ShadcnDropdownMenu.DropdownMenuPortal;

export const DropdownMenuSub = ShadcnDropdownMenu.DropdownMenuSub;

export const DropdownMenuRadioGroup = ShadcnDropdownMenu.DropdownMenuRadioGroup;

export const DropdownMenuSubTrigger = twc(
  ShadcnDropdownMenu.DropdownMenuSubTrigger,
)`[&_svg]:pointer-events-default [&_svg]:size-0 gap-0 [&_svg]:shrink-0`;
export const DropdownMenuSubContent = ShadcnDropdownMenu.DropdownMenuSubContent;

export const DropdownMenuContent = forwardRef<
  React.ElementRef<typeof ShadcnDropdownMenu.DropdownMenuContent>,
  React.ComponentPropsWithoutRef<typeof ShadcnDropdownMenu.DropdownMenuContent>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <ShadcnDropdownMenu.DropdownMenuContent
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      "min-w-[8rem] overflow-hidden rounded-lg border p-2 shadow-md",
      className,
    )}
    {...props}
  />
));

export const DropdownMenuItem = twc(
  ShadcnDropdownMenu.DropdownMenuItem,
)`gap-0 rounded-lg px-3 py-2 text-base font-normal hover:bg-gray-100 focus:bg-gray-100 data-[disabled]:pointer-events-none data-[disabled]:pointer-events-auto [&>svg]:h-auto [&>svg]:w-auto [&>svg]:shrink`;

export const DropdownMenuCheckboxItem =
  ShadcnDropdownMenu.DropdownMenuCheckboxItem;

export const DropdownMenuRadioItem = ShadcnDropdownMenu.DropdownMenuRadioItem;

export const DropdownMenuLabel = ShadcnDropdownMenu.DropdownMenuLabel;

export const DropdownMenuSeparator = ShadcnDropdownMenu.DropdownMenuSeparator;

export const DropdownMenuShortcut = ShadcnDropdownMenu.DropdownMenuShortcut;
