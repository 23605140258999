import { getPublicConfig } from "@/helpers/getPublicConfig";
import { ApolloError } from "@apollo/client";
import { captureException, captureMessage, withScope } from "@sentry/nextjs";
import { type TRPCClientErrorLike } from "@trpc/client";
import cookie from "js-cookie";
import { useTranslation } from "next-i18next";
import { useCallback } from "react";
import { translationKeyByErrorMessage } from "@princess/common/error/errorMessage";
import toast from "@/components/Toast";
import { useRouter } from "next/router";
import { useBannedUserDialogStore } from "@/features/auth/components/store/bannedUserDialogStore";

const { authHeaderKey } = getPublicConfig();
type HandleGraphqlErrorOptions = {
  error: Error;
  fallbackAction?: (error: any) => void;
  showToast?: boolean;
};

export const useErrorHandling = () => {
  const { t } = useTranslation("error");
  const router = useRouter();
  const { openBannedUserDialog } = useBannedUserDialogStore();

  const getSingleErrorString = (message: string, field: string) => {
    if (message.includes("INTR")) {
      return t(`msg:gql_err.intr`);
    }
    if (field === "user" || field === "friend") {
      return t(`msg:gql_err.${message.toLowerCase()}`, {
        field: t(`msg:params.${field}`),
      });
    } else {
      return t(`msg:gql_err.${message.toLowerCase()}`);
    }
  };

  const getErrorString = (error: Error) => {
    // if the error is apollo error, don't know how to handle
    if (!(error instanceof ApolloError)) {
      return error.message;
    }
    const { graphQLErrors } = error;
    return graphQLErrors
      .map(({ message, extensions }) => {
        return getSingleErrorString(
          message,
          extensions && "field" in extensions
            ? (extensions.field as string)
            : "",
        );
      })
      .join("; ");
  };

  const handleTrpcError = useCallback(
    (error: TRPCClientErrorLike<any>) => {
      captureException(error);

      const errorMessage =
        error.message in translationKeyByErrorMessage
          ? translationKeyByErrorMessage[error.message]
          : null;

      toast.error(t(errorMessage ?? "msg:trpc_err.unknown"));
    },
    [t],
  );

  const handleGraphqlError = useCallback(
    ({ error, showToast = true }: HandleGraphqlErrorOptions) => {
      console.log(`error`, JSON.stringify(error));

      if (!(error instanceof ApolloError)) {
        withScope((scope) => {
          scope.setExtras({ error });
          captureMessage(
            "useGraphql.handleGraphqlError - error is not ApolloError " +
              error?.message,
          );
        });
        return;
      }

      const { graphQLErrors, networkError } = error;

      if (!graphQLErrors && !networkError) {
        withScope((scope) => {
          scope.setExtras({ error });
          captureMessage(
            "useGraphql.handleGraphqlError - graphQLErrors expected but received " +
              error?.message,
          );
        });
      }

      if (networkError) {
        captureMessage(
          "useGraphql.handleGraphqlError - networkError received " +
            error?.message,
        );
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(
          async ({
            message,
            extensions: { field, code, exception, bannedUntil } = {},
          }) => {
            if (
              message === "FORB__LOGIN_REQUIRED" ||
              message.includes("AUTH__EXPIRED")
            ) {
              alert(t(`msg:gql_err.${message.toLowerCase()}`));
              window.localStorage.removeItem(authHeaderKey);
              cookie.remove(authHeaderKey);
              router.push("/");
              return;
            }

            if (message === "FORB__USER_BANNED") {
              openBannedUserDialog(
                bannedUntil ? new Date(bannedUntil as string) : null,
              );
              return;
            }

            if (code === "INTERNAL") {
              // Log the whole internal error to sentry
              withScope((scope) => {
                scope.setTag("type", "GRAPHQL_ERROR");
                scope.setTag("code", code);
                scope.setLevel("error");
                captureException(exception);
              });
            }
            if (!code) {
              withScope((scope) => {
                scope.setTag("type", "GRAPHQL_ERROR");
                scope.setTag("code", "UNKNOWN");
                scope.setLevel("error");
                captureException(error);
              });
            }
            if (message && showToast) {
              // Should be normal types of error that can be fount in i18n
              toast.error(getSingleErrorString(message, field as string));
            }
          },
        );
      }
    },
    [getSingleErrorString, router, t],
  );

  return {
    handleTrpcError,
    handleGraphqlError,
    /**
     * return a format error string from apollo error
     */
    getErrorString,
  };
};
