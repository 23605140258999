import DropdownIcon from "public/assets/icons/dropdown.svg";
import { appLanguages } from "@/consts/appLanguage";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/DropdownMenu";
import { useAppLanguage } from "@/featuresPrincess/common/hooks/useAppLanguage";
import type { AppLanguage } from "@/types/appLanguage";
import clsx from "clsx";
import { useMemo } from "react";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { PiGlobe } from "react-icons/pi";

type LanguageOptionDropdownProps = {
  classes?: {
    trigger?: string;
    triggerIcon?: string;
    dropdownIcon?: string;
    chipText?: string;
  };
  labelType?: "short" | "full";
};
export const LanguageOptionDropdown = ({
  classes,
  labelType = "short",
}: LanguageOptionDropdownProps) => {
  const { currentLanguage, changeLanguage } = useAppLanguage();

  const chipText = useMemo(() => {
    const language = appLanguages[currentLanguage];
    return labelType === "short" ? language.labelShort : language.label;
  }, [currentLanguage, labelType]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div
          className={cn(
            "flex cursor-pointer items-center gap-2",
            classes?.trigger,
          )}
        >
          <div className="flex w-max items-center gap-1">
            <PiGlobe className="h-5 w-5 text-gray-500" />
            <span
              className={cn(
                "text-base font-normal text-gray-700",
                classes?.chipText,
              )}
            >
              {chipText}
            </span>
          </div>
          <DropdownIcon
            className={cn("h-3 w-3 text-gray-700", classes?.dropdownIcon)}
          />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-0 max-w-fit">
        {Object.entries(appLanguages).map(([key, value]) => (
          <DropdownMenuItem
            key={key}
            onClick={() => changeLanguage(key as AppLanguage)}
          >
            <span
              className={clsx(
                currentLanguage === key ? "text-primary" : "text-gray-700",
              )}
            >
              {value.label}
            </span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
