import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { type VariantProps, cva } from "class-variance-authority";

const headingVariant = cva("scroll-m-20 tracking-[0px]", {
  variants: {
    variant: {
      normal: "text-gray-700",
      dark: "text-gray-900",
    },
    size: {
      // 48px
      lg: "text-5xl leading-[3rem]",
      // 30px
      md: "text-3xl leading-[2.25rem]",
      // 24px
      sm: "text-2xl leading-[2rem]",
      // 20px
      xs: "text-xl leading-[1.75rem]",
    },
    weight: {
      normal: "font-normal",
      medium: "font-medium",
    },
  },
  defaultVariants: {
    variant: "normal",
    size: "md",
    weight: "normal",
  },
});

export type HeadingVariants = VariantProps<typeof headingVariant>;

export type HeadingProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
> &
  HeadingVariants & {
    // convention: h2 -> h3, h4,h6 -> h5
    as: "h1" | "h3" | "h5";
  };

export const Heading = ({
  as = "h1",
  variant,
  size,
  weight,
  className,
  children,
  ...props
}: HeadingProps) => {
  const Comp = as;

  return (
    <Comp
      className={cn(headingVariant({ variant, size, weight, className }))}
      {...props}
    >
      {children}
    </Comp>
  );
};
