import { Route } from "@/consts";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import FriendIcon from "public/assets/icons/friend.svg";
import SignOutIcon from "public/assets/icons/sign_out.svg";
import SupportIcon from "public/assets/icons/support.svg";
import CreditIcon from "public/assets/icons/credit_gold.svg";
import { TbSettings } from "react-icons/tb";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { useRouter } from "next/router";

export const useUserNavigationItems = () => {
  const { t } = useTranslation("common");
  const { user, logout } = useUserContext();
  const router = useRouter();

  return useMemo(
    () => [
      {
        title: t("common:menu.friend"),
        icon: FriendIcon,
        href: Route.Friend,
      },
      {
        title: t("common:menu.credits"),
        icon: CreditIcon,
        href: Route.Recharge,
      },
      {
        title: t("common:menu.settings"),
        icon: TbSettings,
        href: Route.ProfileSetting,
      },
      {
        title: t("support:title"),
        icon: SupportIcon,
        href: Route.Faq,
      },
      {
        title: t("common:menu.sign_out"),
        icon: SignOutIcon,
        onClick: logout,
      },
    ],
    [logout, router, t, user?.role],
  );
};
