import { useTranslation } from "next-i18next";
import { Text } from "@/modules/client/components/typography/Text";
import Image from "next/image";

export const MaleAdvantagesSection = () => {
  const { t } = useTranslation("home");
  return (
    <div className="w-full bg-white">
      <div className="section-container flex max-w-screen-lg flex-col items-center">
        <div className="flex items-center space-x-16">
          <div className="flex-1">
            <h3 className="mx-auto mb-6 text-center text-3xl text-gray-700 sm:text-left">
              {t("section.maleAdv.title")}
            </h3>
            <div className="grid grid-flow-row grid-cols-1 gap-6 sm:grid-cols-2">
              {Array.from({ length: 4 }).map((_, i) => (
                <div
                  key={i}
                  className="flex w-full flex-col items-start space-y-4"
                >
                  <Text weight="medium" className="text-lightblue-500">
                    {t(`section.maleAdv.item_${i + 1}_title`)}
                  </Text>
                  <Text className="whitespace-pre-line text-justify">
                    {t(`section.maleAdv.item_${i + 1}_description`)}
                  </Text>
                </div>
              ))}
            </div>
          </div>
          <div className="hidden flex-shrink-0 sm:block">
            <Image
              src="/assets/images/male_adv_man.png"
              height={461}
              width={152}
              alt={t("section.maleAdv.title")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
