import React from "react";
import { UserRoleEnum } from "@princess/graphql-codegen/graphql-hooks";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/Button";
import { Text } from "@/modules/client/components/typography/Text";
import CrownOutlineIcon from "public/assets/icons/crown_outline.svg";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { useRouter } from "next/router";
import { Route } from "@/consts";
import { HiChevronRight } from "react-icons/hi";

type SubscriptionOverviewProps = {
  onActionClick?: () => void;
};
export const SubscriptionOverview = ({
  onActionClick,
}: SubscriptionOverviewProps) => {
  const { user } = useUserContext();
  const { t } = useTranslation();
  const router = useRouter();

  if (!user) return null;

  const isPaidMember = user.role !== UserRoleEnum.Normal;

  return (
    <div
      className={cn(
        "flex w-full items-center justify-between gap-2 rounded-lg px-4 py-2",
        isPaidMember
          ? "bg-[linear-gradient(109deg,#FAD787_1.63%,#FFEBD4_47.63%,#FADA69_98.4%)]"
          : "bg-[linear-gradient(109deg,#D7D7D7_1.63%,#F3F3F3_47.63%,#B9B9B9_98.4%)]",
      )}
    >
      <div className="flex-1 gap-1.5">
        <div className="flex items-center gap-1.5">
          <CrownOutlineIcon
            width={20}
            height={20}
            fill={isPaidMember ? "#854D0E" : "#404040"}
          />
          <Text
            weight="medium"
            className={cn(isPaidMember ? "text-yellow-800" : "text-gray-700")}
          >
            {isPaidMember
              ? t(`user:role.${user.role.toLowerCase()}`)
              : t("payment:subscription_plan.title")}
          </Text>
        </div>
        {!isPaidMember && (
          <Text size="sm" className={cn("text-gray-500")}>
            {t("payment:subscription_plan.description")}
          </Text>
        )}
      </div>
      <Button
        size="sm"
        className={cn(
          isPaidMember
            ? "text-primary hover:text-primary-dark flex h-auto items-center bg-transparent px-0 py-0 hover:bg-transparent"
            : "rounded-full bg-white text-gray-900 hover:bg-gray-50",
        )}
        onClick={() => {
          onActionClick?.();
          void router.push(Route.Premium);
        }}
      >
        {isPaidMember
          ? t("payment:subscription_plan.view_privileges")
          : t("user:frd_req.upgrade_action")}
        {isPaidMember && <HiChevronRight className="h-5 w-5" />}
      </Button>
    </div>
  );
};
