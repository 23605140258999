import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/Sheet";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { Skeleton } from "@/components/Skeleton";
import UserMenuIcon from "public/assets/icons/user_menu.svg";
import { useCallback, useState } from "react";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { Logo } from "./Logo";
import { UserFullScreenMenu } from "./userMenu/UserFullScreenMenu";
import { useUserNavigationItems } from "../user/hooks/useUserNavigationItems";
import { useMainNavigationItems } from "../common/hooks/useMainNavigationItems";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { Text } from "@/modules/client/components/typography/Text";
import { AvatarDisplay } from "@/features/user/components/AvatarDisplay";
import type { Myself } from "@princess/common/user/types";

export const BottomTab = () => {
  const { user } = useUserContext();

  return user ? <BottomTabContent user={user} /> : <Skeleton />;
};

const ITEM_CLASSNAME =
  "xs:px-5 z-50 flex cursor-pointer flex-col items-center gap-1 px-3 py-0.5";

const BottomTabContent = ({ user }: { user: Myself }) => {
  const [isOpen, setIsOpen] = useState(false);
  const userNavigationItems = useUserNavigationItems();
  const mainNavigationItems = useMainNavigationItems();
  const { t } = useTranslation("common");

  const getClassName = useCallback(({ isActive }: { isActive: boolean }) => {
    return isActive ? "text-primary-500" : "text-gray-700";
  }, []);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div className="flex items-center justify-between overflow-x-scroll border-t bg-white px-2.5 pb-[env(safe-area-inset-bottom)] sm:justify-around">
      {mainNavigationItems.map((item) => (
        <Link
          className={cn(
            ITEM_CLASSNAME,
            getClassName({ isActive: item.isActive }),
          )}
          key={item.title}
          href={item.href}
        >
          <item.icon className="h-7 w-7" />
          <Text
            className={cn(
              "w-max text-xs",
              getClassName({ isActive: item.isActive }),
            )}
          >
            {item.title}
          </Text>
        </Link>
      ))}
      <Sheet open={isOpen} onOpenChange={() => setIsOpen((isOpen) => !isOpen)}>
        <SheetTrigger asChild>
          <div className={ITEM_CLASSNAME}>
            <div className="relative">
              <AvatarDisplay
                user={user}
                alt={user.nickname ?? user.userId}
                shape="roundedFull"
                size="bottomTabAvatar"
                className={cn(
                  "border-2",
                  isOpen ? "border-primary-500" : "border-transparent",
                )}
              />

              <UserMenuIcon className="absolute bottom-0 right-0 h-4 w-4" />
            </div>
            <Text className="w-max text-xs">{t("menu.me")}</Text>
          </div>
        </SheetTrigger>

        <SheetContent className="w-[80%] p-0 pt-1 sm:max-w-[70%]">
          <SheetHeader>
            <SheetTitle className="border-b border-gray-200 px-3 pb-1">
              <Logo />
            </SheetTitle>
          </SheetHeader>
          <UserFullScreenMenu
            user={user}
            menuItems={userNavigationItems}
            closeMenu={closeMenu}
          />
        </SheetContent>
      </Sheet>
    </div>
  );
};
