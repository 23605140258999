import { UserHeaderTag } from "@/features/user/components/UserHeaderTag";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import {
  GenderEnum,
  UserRoleEnum,
} from "@princess/graphql-codegen/nexus-types";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";

type UserMembershipTagProps = {
  user: { role: UserRoleEnum; gender: string };
  className?: string;
  variant?: "default" | "short";
};

export const UserMembershipTag = ({
  user,
  className,
  variant = "default",
}: UserMembershipTagProps) => {
  const { t } = useTranslation("user");

  const tagLabel = useMemo(() => {
    if (!user.role) return "";
    return t(
      `user:role.${user.role.toLowerCase()}${
        variant === "short" ? "_short" : ""
      }`,
    );
  }, [t, user.role, variant]);

  const tagClasses = useMemo(() => {
    if (user.role === UserRoleEnum.Normal) return "bg-gray-100 text-gray-500";

    if (user.gender === GenderEnum.F) {
      switch (user.role) {
        case UserRoleEnum.Silver:
          return "bg-lightpink-100 text-lightpink-500";
        case UserRoleEnum.Premium:
          return "bg-lightpink-500 text-lightpink-100";
      }
    }

    switch (user.role) {
      case UserRoleEnum.Silver:
        return "bg-lightblue-100 text-lightblue-500";
      case UserRoleEnum.Premium:
        return "bg-lightblue-500 text-lightblue-100";
    }
  }, [user.gender, user.role]);

  return (
    <UserHeaderTag className={cn(tagClasses, className)}>
      {tagLabel}
    </UserHeaderTag>
  );
};
