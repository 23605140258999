import { Route } from "@/consts/Route";
import { Button } from "@/components/Button";
import { Heading } from "@/modules/client/components/typography/Heading";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { HiOutlineChevronLeft } from "react-icons/hi";

export const MobilePageHeader = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const titleByRoute = {
    [Route.PaymentSetting]: t("setting:mobile_title"),
    [Route.ProfileSetting]: t("setting:mobile_title"),
    [Route.AccountSetting]: t("setting:mobile_title"),
  };

  return (
    <div className="header relative z-50 flex w-full items-center justify-center bg-white p-3 md:hidden">
      <Button
        variant="ghost"
        onClick={() => router.back()}
        className="absolute left-0 top-[50%] w-fit -translate-y-[50%] p-2"
      >
        <HiOutlineChevronLeft className="h-6 w-6 text-gray-900" />
      </Button>
      <Heading as="h1" variant="dark" weight="medium" className="text-lg">
        {titleByRoute[router.asPath as Route]}
      </Heading>
    </div>
  );
};
