import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { Text } from "@/modules/client/components/typography/Text";

export type CountingMethod = "normal" | "unicode_double";

interface Props {
  maxLength: number;
  className?: string;
  inputValue: string;
  countingMethod?: CountingMethod;
}

export function InputLength({
  maxLength,
  className,
  inputValue,
  countingMethod = "normal",
}: Props) {
  const getLength = getLengthByMethod[countingMethod];

  const currentLength = getLength(inputValue);

  return (
    <Text
      className={cn(
        "mr-1 text-sm font-normal leading-none text-gray-400",
        className,
      )}
    >
      {`${currentLength} / ${maxLength}`}
    </Text>
  );
}

/*
- Every 2 English characters (letters, spaces, numbers, or common punctuation marks) count as 1 character.
- Other character counts as 1 character.
*/
const getLengthSpecial = (value: string) => {
  let count = 0;
  let englishCharCount = 0;

  for (let char of value) {
    const charCode = char.charCodeAt(0);
    const isEnglishChar = charCode >= 32 && charCode <= 126;

    if (!isEnglishChar) {
      count++;
      continue;
    }

    englishCharCount++;
    if (englishCharCount === 2) {
      count++;
      englishCharCount = 0;
    }
  }

  return count;
};

export const getLengthByMethod: Record<
  CountingMethod,
  (value: string) => number
> = {
  normal: (value) => value.length,
  unicode_double: getLengthSpecial,
};
