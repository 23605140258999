import DropdownIcon from "public/assets/icons/dropdown.svg";

import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { type PublicNavigationItemWithSubItems } from "@/features/common/hooks/usePublicNavigationItems";
import { useIsCurrentRoute } from "@/featuresPrincess/common/hooks/useIsCurrentRoute";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
} from "@/components/HoverCard";

import { Text } from "@/modules/client/components/typography/Text";
import { NavLink } from "@/features/common/layout/header/NavLink";
type NavItemWithSubItemsProps = PublicNavigationItemWithSubItems;
export const NavItemWithSubItems = ({
  title,
  subItems,
}: NavItemWithSubItemsProps) => {
  const { isCurrentRoute } = useIsCurrentRoute();

  const isSubRouteActive = subItems.some(({ href }) => isCurrentRoute(href));

  return (
    <HoverCard openDelay={0}>
      <HoverCardTrigger className="cursor-default" asChild>
        <div className="flex items-center gap-2">
          <Text className={cn(isSubRouteActive && "font-medium")}>{title}</Text>
          <DropdownIcon className="h-3 w-3" />
        </div>
      </HoverCardTrigger>
      <HoverCardContent className="w-max rounded-lg p-2">
        {subItems.map((subItem) => (
          <NavLink
            key={subItem.title}
            href={subItem.href}
            className={cn(
              "w-full rounded-lg px-3 py-2 text-gray-700 hover:bg-gray-100",
              isCurrentRoute(subItem.href)
                ? "text-primary-500"
                : "hover:text-gray-700",
            )}
          >
            {subItem.title}
          </NavLink>
        ))}
      </HoverCardContent>
    </HoverCard>
  );
};
