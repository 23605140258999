import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { Button as ShadcnButton } from "@/modules/external/shadcn/ui/components/ui/button";
import { Loader2 } from "lucide-react";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";

const buttonVariants = cva(
  "focus-visible:ring-ring ring-offset-background min-w-max gap-1 rounded-lg transition-none",
  {
    variants: {
      variant: {
        default: "hover:bg-primary-dark text-white",
        destructive: "bg-gray-100 text-gray-500 hover:bg-gray-200",
        outline: "border border-gray-300 text-gray-500 hover:bg-gray-100",
        secondary:
          "border-primary-500 text-primary-500 hover:bg-primary-light border bg-white",
        ghost:
          "text-primary hover:text-primary-dark h-auto hover:bg-transparent",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "px-5 py-2 text-base",
        sm: "",
        lg: "px-6 text-base",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonVariants & {
    loading?: boolean;
    asChild?: boolean;
  };

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, children, loading = false, disabled, ...props },
    ref,
  ) => {
    return (
      <ShadcnButton
        className={cn(buttonVariants({ variant, size }), className)}
        size={size}
        variant={variant}
        ref={ref}
        disabled={loading || disabled}
        {...props}
      >
        {loading && <Loader2 className="h-4 w-4 animate-spin" />}
        {children}
      </ShadcnButton>
    );
  },
);

Button.displayName = "Button";

export { Button, buttonVariants };
