import { ClientError } from "graphql-request";
import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useMutation, useQuery, useInfiniteQuery, UseMutationOptions, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
};

export enum AppNameEnum {
  Millionbb = 'MILLIONBB',
  PricessDating = 'PRICESS_DATING'
}

export type CmsCloneUserCountWhereInput = {
  userId: Scalars['Int'];
};

export type CmsMessagesPayload = {
  __typename?: 'CmsMessagesPayload';
  count: Scalars['Int'];
  messages: Array<Message>;
};

export type CmsMessagesWhereInput = {
  message?: InputMaybe<SimpleStringFilter>;
  recipant?: InputMaybe<SimpleIntFilter>;
  recipantUser?: InputMaybe<MessageUserWhereInput>;
  sender?: InputMaybe<SimpleIntFilter>;
  senderUser?: InputMaybe<MessageUserWhereInput>;
};

export type CmsPaymentLogsPayload = {
  __typename?: 'CmsPaymentLogsPayload';
  count: Scalars['Int'];
  paymentLogs: Array<PaymentLog>;
};

export type CmsPaymentLogsWhereInput = {
  creditCardLastFour?: InputMaybe<SimpleStringFilter>;
  nickname?: InputMaybe<SimpleStringFilter>;
  paymentMethod?: InputMaybe<SimplePaymentMethodEnumFilter>;
  paymentStatus?: InputMaybe<SimplePaymentLogPaymentStatusEnumFilter>;
  userEmail?: InputMaybe<SimpleStringFilter>;
  userId?: InputMaybe<SimpleIntFilter>;
};

export type CmsReportWhereInput = {
  OR?: InputMaybe<Array<InputMaybe<CmsReportWhereInput>>>;
  createdAt?: InputMaybe<SimpleDateFilter>;
  description?: InputMaybe<SimpleStringFilter>;
  nickname?: InputMaybe<SimpleStringFilter>;
  reportCategory?: InputMaybe<SimpleReportCategoryFilter>;
  reporterId?: InputMaybe<SimpleIntFilter>;
  status?: InputMaybe<SimpleReportStatusEnumFilter>;
  userId?: InputMaybe<SimpleIntFilter>;
};

export type CmsReportsPayload = {
  __typename?: 'CmsReportsPayload';
  count: Scalars['Int'];
  reports: Array<Report>;
};

export type CmsSubscriptionPaymentLogsSubscriptionWhereInput = {
  plan?: InputMaybe<SimpleSubscriptionPlanEnumFilter>;
  status?: InputMaybe<SimpleIntFilter>;
};

export type CmsSubscriptionPaymentLogsWhereInput = {
  OR?: InputMaybe<Array<InputMaybe<CmsSubscriptionPaymentLogsWhereInput>>>;
  creditCardLastFour?: InputMaybe<SimpleStringFilter>;
  isActiveSubscription?: InputMaybe<SimpleBooleanFilter>;
  nickname?: InputMaybe<SimpleStringFilter>;
  paymentLogId?: InputMaybe<SimpleIntFilter>;
  subscription?: InputMaybe<CmsSubscriptionPaymentLogsSubscriptionWhereInput>;
  userId?: InputMaybe<SimpleIntFilter>;
};

export type CmsSubscriptionPlansWhereInput = {
  isActive?: InputMaybe<SimpleBooleanFilter>;
  isPrincess?: InputMaybe<SimpleBooleanFilter>;
};

export type CmsSubscriptionsPayload = {
  __typename?: 'CmsSubscriptionsPayload';
  count: Scalars['Int'];
  subscriptionPaymentLogs: Array<SubscriptionPaymentLog>;
};

export type CmsUserOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
};

export type CmsUserWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<CmsUserWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CmsUserWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CmsUserWhereInput>>>;
  aboutMe?: InputMaybe<SimpleStringFilter>;
  adminRemark?: InputMaybe<SimpleStringFilter>;
  ageVerify?: InputMaybe<SimpleUserVerifyEnumFilter>;
  bannedUntil?: InputMaybe<SimpleDateFilter>;
  contactMethod?: InputMaybe<SimpleStringFilter>;
  dob?: InputMaybe<SimpleDateFilter>;
  email?: InputMaybe<SimpleStringFilter>;
  gender?: InputMaybe<SimpleGenderEnumFilter>;
  id?: InputMaybe<SimpleIntFilter>;
  ip?: InputMaybe<SimpleStringFilter>;
  nickname?: InputMaybe<SimpleStringFilter>;
  nonFriendMsgQuota?: InputMaybe<SimpleIntFilter>;
  phone?: InputMaybe<SimpleStringFilter>;
  rateLevel?: InputMaybe<SimpleUserRateLevelEnumFilter>;
  role?: InputMaybe<SimpleUserRoleEnumFilter>;
  simplifiedUserStatus?: InputMaybe<SimplifiedUserStatusEnum>;
  status?: InputMaybe<SimpleUserStatusEnumFilter>;
  voice?: InputMaybe<SimpleUserVerifyEnumFilter>;
};

export type CmsUsersPayload = {
  __typename?: 'CmsUsersPayload';
  count: Scalars['Int'];
  users: Array<User>;
};

export type CreateInternalFaqInput = {
  answer: Scalars['String'];
  question: Scalars['String'];
};

export enum CreditActionEnum {
  AdminEdit = 'ADMIN_EDIT',
  AdminEditNewCms = 'ADMIN_EDIT_NEW_CMS',
  AdminUpgrade = 'ADMIN_UPGRADE',
  AdminUpgradeRetool = 'ADMIN_UPGRADE_RETOOL',
  AnonymousRate = 'ANONYMOUS_RATE',
  BonusGrasRate = 'BONUS_GRAS_RATE',
  BonusGrasVerify = 'BONUS_GRAS_VERIFY',
  BonusNfmqRate = 'BONUS_NFMQ_RATE',
  FriendRequestAccept = 'FRIEND_REQUEST_ACCEPT',
  FriendRequestCancel = 'FRIEND_REQUEST_CANCEL',
  FriendRequestReject = 'FRIEND_REQUEST_REJECT',
  FriendRequestSent = 'FRIEND_REQUEST_SENT',
  MakeFriendDirectly = 'MAKE_FRIEND_DIRECTLY',
  PrivateMsg = 'PRIVATE_MSG',
  PurchaseCredit = 'PURCHASE_CREDIT',
  PurchaseFrdQuota = 'PURCHASE_FRD_QUOTA',
  PurchaseNfmq = 'PURCHASE_NFMQ',
  PurchasePremium = 'PURCHASE_PREMIUM',
  PurchaseWine = 'PURCHASE_WINE',
  RefundCredit = 'REFUND_CREDIT',
  RefundPremium = 'REFUND_PREMIUM',
  ReturnWine = 'RETURN_WINE',
  ReturnWineDeprecated = 'RETURN_WINE_DEPRECATED',
  SystemDistribute = 'SYSTEM_DISTRIBUTE',
  UpdateNickname = 'UPDATE_NICKNAME',
  WineToCredit = 'WINE_TO_CREDIT'
}

export enum DatatypeEnum {
  Base64 = 'BASE64',
  Text = 'TEXT'
}

export enum EducationLevelEnum {
  AtUniversity = 'AT_UNIVERSITY',
  BachelorDegree = 'BACHELOR_DEGREE',
  HighSchool = 'HIGH_SCHOOL',
  MasterDegree = 'MASTER_DEGREE',
  OnGraduateProgram = 'ON_GRADUATE_PROGRAM',
  Phd = 'PHD',
  TradeSchool = 'TRADE_SCHOOL'
}

export enum FileStatusEnum {
  Active = 'ACTIVE',
  Banned = 'BANNED',
  Deleted = 'DELETED'
}

export type FileUpload = {
  __typename?: 'FileUpload';
  audioDurationInSec?: Maybe<Scalars['Float']>;
  audioFormat?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  height?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  publicId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export enum GenderEnum {
  F = 'F',
  M = 'M'
}

export type GetBlacklistReocrdsPayload = {
  __typename?: 'GetBlacklistReocrdsPayload';
  blacklistedByUserIds?: Maybe<Array<Scalars['Int']>>;
  blacklistedUserIds?: Maybe<Array<Scalars['Int']>>;
};

export type GetHasIssuedNfmqThisMonthPayload = {
  __typename?: 'GetHasIssuedNfmqThisMonthPayload';
  issued: Scalars['Boolean'];
};

export type GetManyCloneUserWhereInput = {
  userId: Scalars['Int'];
};

export type InternalFaq = {
  __typename?: 'InternalFaq';
  answer: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  question: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum IsAdultEnum {
  Adult = 'ADULT',
  Family = 'FAMILY'
}

export enum LogTypeEnum {
  ChargeBack = 'CHARGE_BACK',
  Payment = 'PAYMENT',
  PayRequest = 'PAY_REQUEST',
  PayResponse = 'PAY_RESPONSE',
  SubscribeCancel = 'SUBSCRIBE_CANCEL',
  SubscribeExpiry = 'SUBSCRIBE_EXPIRY',
  SubscribeExtend = 'SUBSCRIBE_EXTEND',
  SubscribeInitial = 'SUBSCRIBE_INITIAL',
  SubscribeRebill = 'SUBSCRIBE_REBILL',
  SubscribeRequest = 'SUBSCRIBE_REQUEST',
  Undefined = 'UNDEFINED'
}

export type Message = {
  __typename?: 'Message';
  becomeFriendDate?: Maybe<Scalars['DateTime']>;
  charmValue?: Maybe<Scalars['Int']>;
  createDt: Scalars['DateTime'];
  filename?: Maybe<Scalars['String']>;
  filteredMessage: Scalars['String'];
  giftCaption?: Maybe<Scalars['String']>;
  giftName?: Maybe<Scalars['String']>;
  giftThumbnailUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: Maybe<MessageImage>;
  imageUrl?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  recipant: Scalars['Int'];
  recipantUser: User;
  sender: Scalars['Int'];
  senderUser: User;
  status: MessageStatusEnum;
  updateDt: Scalars['DateTime'];
};

export type MessageImage = {
  __typename?: 'MessageImage';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  imageFile: FileUpload;
  messageId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export enum MessageStatusEnum {
  Accept = 'ACCEPT',
  Cancelled = 'CANCELLED',
  Deleted = 'DELETED',
  Read = 'READ',
  Reject = 'REJECT',
  Request = 'REQUEST',
  Sent = 'SENT'
}

export type MessageUserWhereInput = {
  ageVerify?: InputMaybe<SimpleUserVerifyEnumFilter>;
  nickname?: InputMaybe<SimpleStringFilter>;
  rateLevel?: InputMaybe<SimpleUserRateLevelEnumFilter>;
  voice?: InputMaybe<SimpleUserVerifyEnumFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateSubscriptionRebill?: Maybe<PrincessSubscription>;
  cmsDeleteOneUserPhoto?: Maybe<User>;
  cmsUpdateOneReport?: Maybe<Scalars['Int']>;
  cmsUpdateOneReportImage?: Maybe<Scalars['Int']>;
  cmsUpdateOneUser?: Maybe<User>;
  cmsUpdateUserAdminRemark?: Maybe<Scalars['Int']>;
  cmsUpdateUserCloneGroup?: Maybe<User>;
  cmsUpdateUserCredit?: Maybe<User>;
  cmsUpgradeOneUser?: Maybe<User>;
  collectMonthlyNfmq?: Maybe<Scalars['Boolean']>;
  createInternalFaq?: Maybe<InternalFaq>;
  deleteOneInternalFaq: InternalFaq;
  deleteOneUser: Scalars['Int'];
  reportUser?: Maybe<Scalars['Int']>;
  terminateSubscription?: Maybe<Subscription>;
  updateUserAvatar?: Maybe<User>;
  userAcceptTerms?: Maybe<User>;
  userOnboard: UserOnboardPayload;
};


export type MutationCmsDeleteOneUserPhotoArgs = {
  data: UserUpdateInput;
  reason: Scalars['String'];
  where: UserWhereUniqueInput;
};


export type MutationCmsUpdateOneReportArgs = {
  data: ReportUpdateInput;
  where: ReportWhereUniqueInput;
};


export type MutationCmsUpdateOneReportImageArgs = {
  data: UpdateOneReportImageInput;
  id: Scalars['Int'];
};


export type MutationCmsUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationCmsUpdateUserAdminRemarkArgs = {
  imageUrls?: InputMaybe<Array<Scalars['String']>>;
  remark: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationCmsUpdateUserCloneGroupArgs = {
  data: UpdateUserCloneGroupInput;
};


export type MutationCmsUpdateUserCreditArgs = {
  data: UpdateUserCreditInput;
  where: UserWhereUniqueInput;
};


export type MutationCmsUpgradeOneUserArgs = {
  data: UpgradeOneUserInput;
  where: UserWhereUniqueInput;
};


export type MutationCreateInternalFaqArgs = {
  data: CreateInternalFaqInput;
};


export type MutationDeleteOneInternalFaqArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteOneUserArgs = {
  userId: Scalars['Int'];
};


export type MutationReportUserArgs = {
  data: ReportUserInputArgs;
};


export type MutationTerminateSubscriptionArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateUserAvatarArgs = {
  avatar?: InputMaybe<Scalars['String']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
};


export type MutationUserOnboardArgs = {
  data: UserOnboardData;
};

export type PaymentLog = {
  __typename?: 'PaymentLog';
  amount: Scalars['Int'];
  billingAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creditCardBrand?: Maybe<Scalars['String']>;
  creditCardLastFour?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  holderName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  isTesting?: Maybe<Scalars['Boolean']>;
  logType: Scalars['Int'];
  paymentStatus: PaymentLogPaymentStatusEnum;
  paymentType: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  refTxnId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Int'];
};

export enum PaymentLogPaymentStatusEnum {
  DisputeUnderReview = 'DISPUTE_UNDER_REVIEW',
  Refunded = 'REFUNDED',
  Succeeded = 'SUCCEEDED'
}

export enum PaymentMethodEnum {
  AdminTesting = 'ADMIN_TESTING',
  CreditCard = 'CREDIT_CARD',
  Offline = 'OFFLINE'
}

export enum PaymentTypeEnum {
  FxBitManual = 'FX_BIT_MANUAL',
  Offline = 'OFFLINE',
  Stripe = 'STRIPE',
  Verotel = 'VEROTEL'
}

export enum PermissionEnum {
  AllowAll = 'ALLOW_ALL',
  OnlyFriend = 'ONLY_FRIEND'
}

export type PrincessReport = {
  __typename?: 'PrincessReport';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  filename?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  images: Array<PrincessReportImage>;
  reporter: PrincessUser;
  reporterId: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<PrincessUser>;
  userId?: Maybe<Scalars['Int']>;
};

export type PrincessReportImage = {
  __typename?: 'PrincessReportImage';
  id: Scalars['Int'];
  reportId: Scalars['Int'];
  url: Scalars['String'];
};

export type PrincessSubscription = {
  __typename?: 'PrincessSubscription';
  amount: Scalars['Int'];
  createDt: Scalars['DateTime'];
  currency: Scalars['String'];
  id: Scalars['Int'];
  lastModifyDt: Scalars['DateTime'];
  nextChargeDt: Scalars['DateTime'];
  plan: Scalars['String'];
  status: Scalars['Int'];
  subscriptionPlan?: Maybe<PrincessSubscriptionPlan>;
};

export type PrincessSubscriptionPlan = {
  __typename?: 'PrincessSubscriptionPlan';
  activeDays: Scalars['Int'];
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  frdQuota: Scalars['Int'];
  id: Scalars['String'];
  isPrincess: Scalars['Boolean'];
  nonFriendMsgQuota: Scalars['Int'];
  role: UserRoleEnum;
  updatedAt: Scalars['DateTime'];
};

export type PrincessUser = {
  __typename?: 'PrincessUser';
  ageVerify: UserVerifyEnum;
  avatar?: Maybe<Scalars['String']>;
  avatarBanned: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['Date']>;
  gender: GenderEnum;
  id: Scalars['Int'];
  logo?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  rateLevel: UserRateLevelEnum;
  role: UserRoleEnum;
  status: UserStatusEnum;
  voice: UserVerifyEnum;
};

export enum PrismaUserContactPrivacyEnum {
  AllPaid = 'ALL_PAID',
  NoOne = 'NO_ONE',
  OnlyPremium = 'ONLY_PREMIUM'
}

export enum PrismaUserStatusEnum {
  Banned = 'BANNED',
  Deleted = 'DELETED',
  NotVerify = 'NOT_VERIFY',
  ProfileCompleted = 'PROFILE_COMPLETED',
  Verified = 'VERIFIED'
}

export type Product = {
  __typename?: 'Product';
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  price: Scalars['Int'];
  rewardQuantity: Scalars['Int'];
  type: ProductType;
  updatedAt: Scalars['DateTime'];
};

export enum ProductType {
  Credit = 'CREDIT',
  FrdQuota = 'FRD_QUOTA',
  NonFrdMsgQuota = 'NON_FRD_MSG_QUOTA'
}

export type Query = {
  __typename?: 'Query';
  cmsCloneUserCount: Scalars['Int'];
  cmsGetManyCloneUser: Array<User>;
  cmsGetUserAdminRemark: UserAdminRemark;
  cmsInternalFaqs: Array<InternalFaq>;
  cmsMessageCount: Scalars['Int'];
  cmsMessages: CmsMessagesPayload;
  cmsPaymentLogs?: Maybe<CmsPaymentLogsPayload>;
  cmsPaymentLogsCount: Scalars['Int'];
  cmsReportCount: Scalars['Int'];
  cmsReports: CmsReportsPayload;
  cmsSubscriptionCount: Scalars['Int'];
  cmsSubscriptionPlans: Array<SubscriptionPlan>;
  cmsSubscriptions?: Maybe<CmsSubscriptionsPayload>;
  cmsUserCount: Scalars['Int'];
  cmsUsers?: Maybe<CmsUsersPayload>;
  friendCount: Scalars['Int'];
  friends: Array<PrincessUser>;
  getBlacklistReocrds?: Maybe<GetBlacklistReocrdsPayload>;
  hasIssuedNfmqThisMonth?: Maybe<GetHasIssuedNfmqThisMonthPayload>;
  searchUsers: SearchUsersPayload;
  viewCount: Scalars['Int'];
  views: Array<PrincessUser>;
};


export type QueryCmsCloneUserCountArgs = {
  where: CmsCloneUserCountWhereInput;
};


export type QueryCmsGetManyCloneUserArgs = {
  where: GetManyCloneUserWhereInput;
};


export type QueryCmsGetUserAdminRemarkArgs = {
  where: UserWhereUniqueInput;
};


export type QueryCmsMessagesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CmsMessagesWhereInput>;
};


export type QueryCmsPaymentLogsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CmsPaymentLogsWhereInput>;
};


export type QueryCmsPaymentLogsCountArgs = {
  where?: InputMaybe<CmsPaymentLogsWhereInput>;
};


export type QueryCmsReportCountArgs = {
  where?: InputMaybe<CmsReportWhereInput>;
};


export type QueryCmsReportsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where: CmsReportWhereInput;
};


export type QueryCmsSubscriptionCountArgs = {
  where?: InputMaybe<CmsSubscriptionPaymentLogsWhereInput>;
};


export type QueryCmsSubscriptionPlansArgs = {
  where: CmsSubscriptionPlansWhereInput;
};


export type QueryCmsSubscriptionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CmsSubscriptionPaymentLogsWhereInput>;
};


export type QueryCmsUserCountArgs = {
  where?: InputMaybe<CmsUserWhereInput>;
};


export type QueryCmsUsersArgs = {
  orderBy?: InputMaybe<Array<CmsUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CmsUserWhereInput>;
};


export type QueryFriendsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QuerySearchUsersArgs = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
  where?: SearchUsersWhereInput;
};


export type QueryViewCountArgs = {
  where: ViewsWhereInput;
};


export type QueryViewsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where: ViewsWhereInput;
};

export type RateSummary = {
  __typename?: 'RateSummary';
  averageRate: Scalars['Float'];
  totalRaters: Scalars['Int'];
};

export type Report = {
  __typename?: 'Report';
  becomeFriendDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  filename?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  images: Array<ReportImage>;
  remark?: Maybe<Scalars['String']>;
  reportCategory?: Maybe<ReportCategory>;
  reportItem?: Maybe<ReportItem>;
  reporter: User;
  reporterId: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};

export enum ReportCategory {
  Commercial = 'COMMERCIAL',
  Discrimination = 'DISCRIMINATION',
  Fraud = 'FRAUD',
  Sexual = 'SEXUAL',
  Violence = 'VIOLENCE'
}

export type ReportImage = {
  __typename?: 'ReportImage';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  image: FileUpload;
  reportId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export enum ReportItem {
  Message = 'MESSAGE',
  Others = 'OTHERS',
  Profile = 'PROFILE'
}

export enum ReportStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
  Invalid = 'INVALID',
  Proceeded = 'PROCEEDED'
}

/** This is a description of a Node */
export type ReportUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReportStatusEnum>;
};

export type ReportUserInputArgs = {
  imageUrls?: InputMaybe<Array<Scalars['String']>>;
  reason: Scalars['String'];
  reportCategory: ReportCategory;
  reportItem: ReportItem;
  userId: Scalars['Int'];
};

export type ReportWhereUniqueInput = {
  id: Scalars['Int'];
};

export enum RestrictedLevelEnum {
  Banned = 'BANNED',
  BannedPending = 'BANNED_PENDING',
  MillionbbOnly = 'MILLIONBB_ONLY',
  MillionbbOnlyPending = 'MILLIONBB_ONLY_PENDING',
  Normal = 'NORMAL'
}

export enum SearchTargetEnum {
  Considering = 'CONSIDERING',
  LongOrShortTerm = 'LONG_OR_SHORT_TERM',
  LongTerm = 'LONG_TERM',
  NewFriend = 'NEW_FRIEND',
  ShortOrLongTerm = 'SHORT_OR_LONG_TERM',
  ShortTerm = 'SHORT_TERM'
}

export type SearchUser = {
  __typename?: 'SearchUser';
  ageVerify: UserVerifyEnum;
  avatar?: Maybe<Scalars['String']>;
  avatarBanned: Scalars['Boolean'];
  charmValue?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  contactPrivacy?: Maybe<UserContactPrivacyEnum>;
  createdAt: Scalars['DateTime'];
  dob?: Maybe<Scalars['Date']>;
  educationLevel?: Maybe<EducationLevelEnum>;
  gender: GenderEnum;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  lastSeen: Scalars['DateTime'];
  minFrdWine?: Maybe<Scalars['Int']>;
  nickname?: Maybe<Scalars['String']>;
  rateLevel: UserRateLevelEnum;
  role: UserRoleEnum;
  searchTarget: SearchTargetEnum;
  status: UserStatusEnum;
  updatedAt: Scalars['DateTime'];
  voice: UserVerifyEnum;
  weight?: Maybe<Scalars['Int']>;
};

export type SearchUsersPayload = {
  __typename?: 'SearchUsersPayload';
  count: Scalars['Int'];
  users: Array<SearchUser>;
};

export type SearchUsersWhereInput = {
  aboutBody?: InputMaybe<SimpleUserAboutBodyEnumFilter>;
  aboutDrink?: InputMaybe<SimpleUserAboutDrinkEnumFilter>;
  aboutEconomy?: InputMaybe<SimpleUserAboutEconomyEnumFilter>;
  aboutHeight?: InputMaybe<SimpleUserAboutHeightEnumFilter>;
  aboutSmoke?: InputMaybe<SimpleUserAboutSmokeEnumFilter>;
  aboutWaist?: InputMaybe<SimpleUserAboutWaistEnumFilter>;
  ageVerify?: InputMaybe<SimpleUserVerifyEnumFilter>;
  city?: InputMaybe<SimpleStringFilter>;
  contactPrivacy?: InputMaybe<SimpleUserContactPrivacyEnumFilter>;
  dob?: InputMaybe<SimpleDateFilter>;
  drinkingHabit?: InputMaybe<SimpleUserDrinkingHabitEnumFilter>;
  educationLevel?: InputMaybe<SimpleEducationLevelEnumFilter>;
  favouriteUsers?: InputMaybe<Scalars['Boolean']>;
  favouritedBy?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<GenderEnum>;
  height?: InputMaybe<SimpleIntFilter>;
  lastSeen?: InputMaybe<SimpleDateFilter>;
  nickname?: InputMaybe<SimpleStringFilter>;
  princessDating?: InputMaybe<SimpleBooleanFilter>;
  rateLevel?: InputMaybe<SimpleUserRateLevelEnumFilter>;
  role?: InputMaybe<SimpleUserRoleEnumFilter>;
  searchTarget?: InputMaybe<SimpleSearchTargetEnumFilter>;
  smokingHabit?: InputMaybe<SimpleUserSmokingHabitEnumFilter>;
  userContactPrivacy?: InputMaybe<SimplePrismaUserContactPrivacyEnumFilter>;
  voice?: InputMaybe<SimpleUserVerifyEnumFilter>;
  weight?: InputMaybe<SimpleIntFilter>;
};

export type SimpleAppNameEnumFilter = {
  equals?: InputMaybe<AppNameEnum>;
  in?: InputMaybe<Array<AppNameEnum>>;
};

export type SimpleBooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
};

export type SimpleCreditActionEnumFilter = {
  equals?: InputMaybe<CreditActionEnum>;
  in?: InputMaybe<Array<CreditActionEnum>>;
};

export type SimpleDatatypeEnumFilter = {
  equals?: InputMaybe<DatatypeEnum>;
  in?: InputMaybe<Array<DatatypeEnum>>;
};

export type SimpleDateFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
};

export type SimpleEducationLevelEnumFilter = {
  equals?: InputMaybe<EducationLevelEnum>;
  in?: InputMaybe<Array<EducationLevelEnum>>;
};

export type SimpleFileStatusEnumFilter = {
  equals?: InputMaybe<FileStatusEnum>;
  in?: InputMaybe<Array<FileStatusEnum>>;
};

export type SimpleGenderEnumFilter = {
  equals?: InputMaybe<GenderEnum>;
  in?: InputMaybe<Array<GenderEnum>>;
};

export type SimpleIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
};

export type SimpleIsAdultEnumFilter = {
  equals?: InputMaybe<IsAdultEnum>;
  in?: InputMaybe<Array<IsAdultEnum>>;
};

export type SimpleLogTypeEnumFilter = {
  equals?: InputMaybe<LogTypeEnum>;
  in?: InputMaybe<Array<LogTypeEnum>>;
};

export type SimpleMessageStatusEnumFilter = {
  equals?: InputMaybe<MessageStatusEnum>;
  in?: InputMaybe<Array<MessageStatusEnum>>;
};

export type SimplePaymentLogPaymentStatusEnumFilter = {
  equals?: InputMaybe<PaymentLogPaymentStatusEnum>;
  in?: InputMaybe<Array<PaymentLogPaymentStatusEnum>>;
};

export type SimplePaymentMethodEnumFilter = {
  equals?: InputMaybe<PaymentMethodEnum>;
  in?: InputMaybe<Array<PaymentMethodEnum>>;
};

export type SimplePaymentTypeEnumFilter = {
  equals?: InputMaybe<PaymentTypeEnum>;
  in?: InputMaybe<Array<PaymentTypeEnum>>;
};

export type SimplePermissionEnumFilter = {
  equals?: InputMaybe<PermissionEnum>;
  in?: InputMaybe<Array<PermissionEnum>>;
};

export type SimplePrismaUserContactPrivacyEnumFilter = {
  equals?: InputMaybe<PrismaUserContactPrivacyEnum>;
  in?: InputMaybe<Array<PrismaUserContactPrivacyEnum>>;
};

export type SimpleReportCategoryFilter = {
  equals?: InputMaybe<ReportCategory>;
  in?: InputMaybe<Array<ReportCategory>>;
};

export type SimpleReportStatusEnumFilter = {
  equals?: InputMaybe<ReportStatusEnum>;
  in?: InputMaybe<Array<ReportStatusEnum>>;
};

export type SimpleRestrictedLevelEnumFilter = {
  equals?: InputMaybe<RestrictedLevelEnum>;
  in?: InputMaybe<Array<RestrictedLevelEnum>>;
};

export type SimpleSearchTargetEnumFilter = {
  equals?: InputMaybe<SearchTargetEnum>;
  in?: InputMaybe<Array<SearchTargetEnum>>;
};

export type SimpleStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type SimpleSubscriptionPlanEnumFilter = {
  equals?: InputMaybe<SubscriptionPlanEnum>;
  in?: InputMaybe<Array<SubscriptionPlanEnum>>;
};

export type SimpleSubscriptionStatusEnumFilter = {
  equals?: InputMaybe<SubscriptionStatusEnum>;
  in?: InputMaybe<Array<SubscriptionStatusEnum>>;
};

export type SimpleTicketStatusEnumFilter = {
  equals?: InputMaybe<TicketStatusEnum>;
  in?: InputMaybe<Array<TicketStatusEnum>>;
};

export type SimpleTicketTypeEnumFilter = {
  equals?: InputMaybe<TicketTypeEnum>;
  in?: InputMaybe<Array<TicketTypeEnum>>;
};

export type SimpleUserAboutBodyEnumFilter = {
  equals?: InputMaybe<UserAboutBodyEnum>;
  in?: InputMaybe<Array<UserAboutBodyEnum>>;
};

export type SimpleUserAboutDrinkEnumFilter = {
  equals?: InputMaybe<UserAboutDrinkEnum>;
  in?: InputMaybe<Array<UserAboutDrinkEnum>>;
};

export type SimpleUserAboutEconomyEnumFilter = {
  equals?: InputMaybe<UserAboutEconomyEnum>;
  in?: InputMaybe<Array<UserAboutEconomyEnum>>;
};

export type SimpleUserAboutHeightEnumFilter = {
  equals?: InputMaybe<UserAboutHeightEnum>;
  in?: InputMaybe<Array<UserAboutHeightEnum>>;
};

export type SimpleUserAboutSmokeEnumFilter = {
  equals?: InputMaybe<UserAboutSmokeEnum>;
  in?: InputMaybe<Array<UserAboutSmokeEnum>>;
};

export type SimpleUserAboutWaistEnumFilter = {
  equals?: InputMaybe<UserAboutWaistEnum>;
  in?: InputMaybe<Array<UserAboutWaistEnum>>;
};

export type SimpleUserContactPrivacyEnumFilter = {
  equals?: InputMaybe<UserContactPrivacyEnum>;
  in?: InputMaybe<Array<UserContactPrivacyEnum>>;
};

export type SimpleUserDateExpenseEnumFilter = {
  equals?: InputMaybe<UserDateExpenseEnum>;
  in?: InputMaybe<Array<UserDateExpenseEnum>>;
};

export type SimpleUserDrinkingHabitEnumFilter = {
  equals?: InputMaybe<UserDrinkingHabitEnum>;
  in?: InputMaybe<Array<UserDrinkingHabitEnum>>;
};

export type SimpleUserFavStatusEnumFilter = {
  equals?: InputMaybe<UserFavStatusEnum>;
  in?: InputMaybe<Array<UserFavStatusEnum>>;
};

export type SimpleUserFriendStatusEnumFilter = {
  equals?: InputMaybe<UserFriendStatusEnum>;
  in?: InputMaybe<Array<UserFriendStatusEnum>>;
};

export type SimpleUserObjectKeyEnumFilter = {
  equals?: InputMaybe<UserObjectKeyEnum>;
  in?: InputMaybe<Array<UserObjectKeyEnum>>;
};

export type SimpleUserRateLevelEnumFilter = {
  equals?: InputMaybe<UserRateLevelEnum>;
  in?: InputMaybe<Array<UserRateLevelEnum>>;
};

export type SimpleUserRoleEnumFilter = {
  equals?: InputMaybe<UserRoleEnum>;
  in?: InputMaybe<Array<UserRoleEnum>>;
};

export type SimpleUserSmokingHabitEnumFilter = {
  equals?: InputMaybe<UserSmokingHabitEnum>;
  in?: InputMaybe<Array<UserSmokingHabitEnum>>;
};

export type SimpleUserStatusEnumFilter = {
  equals?: InputMaybe<UserStatusEnum>;
  in?: InputMaybe<Array<UserStatusEnum>>;
};

export type SimpleUserVerifyEnumFilter = {
  equals?: InputMaybe<UserVerifyEnum>;
  in?: InputMaybe<Array<UserVerifyEnum>>;
};

export type SimpleWineReturnStatusEnumFilter = {
  equals?: InputMaybe<WineReturnStatusEnum>;
  in?: InputMaybe<Array<WineReturnStatusEnum>>;
};

export enum SimplifiedUserStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Subscription = {
  __typename?: 'Subscription';
  amount: Scalars['Int'];
  createDt: Scalars['DateTime'];
  currency: Scalars['String'];
  id: Scalars['Int'];
  lastModifyDt: Scalars['DateTime'];
  nextChargeDt: Scalars['DateTime'];
  paymentLog?: Maybe<PaymentLog>;
  paymentType: Scalars['Int'];
  plan: Scalars['String'];
  status: Scalars['Int'];
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  token: Scalars['String'];
  user: User;
  userId: Scalars['Int'];
};

export type SubscriptionPaymentLog = {
  __typename?: 'SubscriptionPaymentLog';
  amount: Scalars['Int'];
  billingAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creditCardBrand?: Maybe<Scalars['String']>;
  creditCardLastFour?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  holderName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  isTesting: Scalars['Boolean'];
  logType: Scalars['Int'];
  paymentType: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  refTxnId?: Maybe<Scalars['String']>;
  subscription: SubscriptionPaymentLogSubscription;
  subscriptionId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Int'];
};

export type SubscriptionPaymentLogSubscription = {
  __typename?: 'SubscriptionPaymentLogSubscription';
  id: Scalars['Int'];
  nextChargeDt: Scalars['DateTime'];
  status: Scalars['Int'];
  subscriptionPlan: SubscriptionPlan;
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  activeDays: Scalars['Int'];
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  frdQuota: Scalars['Int'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  isPrincess: Scalars['Boolean'];
  nonFriendMsgQuota: Scalars['Int'];
  role: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export enum SubscriptionPlanEnum {
  HqMember = 'HQ_MEMBER',
  HLvAug2021 = 'H_LV_AUG2021',
  HLvJun2023 = 'H_LV_JUN2023',
  HLvJun2024 = 'H_LV_JUN2024',
  HLvMember_60D = 'H_LV_MEMBER_60D',
  HLvMember_90D = 'H_LV_MEMBER_90D',
  HLvMember_2021 = 'H_LV_MEMBER_2021',
  HLvOct2021 = 'H_LV_OCT2021',
  MbbPremium_3Apr_2024 = 'MBB_PREMIUM_3_APR_2024',
  MbbPremium_6Apr_2024 = 'MBB_PREMIUM_6_APR_2024',
  MbbSilver_3Apr_2024 = 'MBB_SILVER_3_APR_2024',
  MbbSilver_6Apr_2024 = 'MBB_SILVER_6_APR_2024',
  Premium_60D = 'PREMIUM_60D',
  Premium_90D = 'PREMIUM_90D',
  Premium_2021 = 'PREMIUM_2021',
  PremiumAug2021 = 'PREMIUM_AUG2021',
  PremiumJun2023 = 'PREMIUM_JUN2023',
  PremiumJun2024 = 'PREMIUM_JUN2024',
  PremiumOct2021 = 'PREMIUM_OCT2021'
}

export enum SubscriptionStatusEnum {
  Active = 'ACTIVE',
  InactiveByAdmin = 'INACTIVE_BY_ADMIN',
  InactiveBySystem = 'INACTIVE_BY_SYSTEM',
  InactiveByUser = 'INACTIVE_BY_USER',
  Nonrebill = 'NONREBILL'
}

export enum TicketStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Proceeded = 'PROCEEDED'
}

export enum TicketTypeEnum {
  Admin = 'ADMIN',
  User = 'USER'
}

export type UpdateOneReportImageInput = {
  imageUrl: Scalars['String'];
};

export type UpdateUserCloneGroupInput = {
  cloneUserId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type UpdateUserCreditInput = {
  credit?: InputMaybe<Scalars['Int']>;
  currentCredit?: InputMaybe<Scalars['Int']>;
  currentFrdQuota?: InputMaybe<Scalars['Int']>;
  currentNonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  frdQuota?: InputMaybe<Scalars['Int']>;
  nonFriendMsgQuota?: InputMaybe<Scalars['Int']>;
  remark?: InputMaybe<Scalars['String']>;
};

export type UpgradeOneUserInput = {
  imageUrl: Scalars['String'];
  isTesting?: InputMaybe<Scalars['Boolean']>;
  planId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  aboutBody?: Maybe<Scalars['Int']>;
  aboutDrink?: Maybe<Scalars['Int']>;
  aboutEconomy?: Maybe<Scalars['Int']>;
  aboutHeight?: Maybe<Scalars['Int']>;
  aboutMe?: Maybe<Scalars['String']>;
  aboutSmoke?: Maybe<Scalars['Int']>;
  aboutStyle?: Maybe<Scalars['Int']>;
  aboutWaist?: Maybe<Scalars['Int']>;
  adminRemark?: Maybe<Scalars['String']>;
  adminRemarkImages?: Maybe<Array<UserAdminRemarkImage>>;
  adminRemarkUpdatedAt?: Maybe<Scalars['DateTime']>;
  ageMax?: Maybe<Scalars['Int']>;
  ageMin?: Maybe<Scalars['Int']>;
  ageVerify: Scalars['Int'];
  avatar?: Maybe<Scalars['String']>;
  avatarBanned: Scalars['Boolean'];
  avatarFull?: Maybe<Scalars['String']>;
  avatarHalf?: Maybe<Scalars['String']>;
  averageRating: Scalars['Float'];
  bannedUntil?: Maybe<Scalars['Date']>;
  city?: Maybe<Scalars['String']>;
  contactPrivacy?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  credit: Scalars['Int'];
  dateExpense?: Maybe<Scalars['Int']>;
  dateOutline?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  emptyWine: Scalars['Int'];
  frdQuota: Scalars['Int'];
  gender: GenderEnum;
  id: Scalars['Int'];
  lang?: Maybe<Scalars['String']>;
  lastIp?: Maybe<UserLastIp>;
  lastIpv4?: Maybe<Scalars['String']>;
  lastSeen: Scalars['DateTime'];
  line?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  logoFull?: Maybe<Scalars['String']>;
  logoHalf?: Maybe<Scalars['String']>;
  millionbb?: Maybe<Scalars['Boolean']>;
  minFrdWine?: Maybe<Scalars['Int']>;
  msgQuota: Scalars['Int'];
  nickname?: Maybe<Scalars['String']>;
  nonFriendMsgQuota: Scalars['Int'];
  onboardedAt?: Maybe<Scalars['DateTime']>;
  phone?: Maybe<Scalars['String']>;
  premiumEndDt: Scalars['DateTime'];
  princessDating?: Maybe<Scalars['Boolean']>;
  rateLevel: Scalars['Int'];
  rateSummary?: Maybe<RateSummary>;
  raterCount: Scalars['Int'];
  refId?: Maybe<Scalars['String']>;
  restrictedLevel: Scalars['Int'];
  role: Scalars['Int'];
  silverEndDt: Scalars['DateTime'];
  status: Scalars['Int'];
  telegram?: Maybe<Scalars['String']>;
  totalFriends: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  verifiedAt?: Maybe<Scalars['DateTime']>;
  vip: Scalars['Int'];
  voice: Scalars['Int'];
  voiceFile?: Maybe<FileUpload>;
  wechat?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  wine: Scalars['Int'];
};

export enum UserAboutBodyEnum {
  Athletic = 'ATHLETIC',
  Normal = 'NORMAL',
  Other = 'OTHER',
  Stout = 'STOUT',
  Superfluous = 'SUPERFLUOUS',
  Thin = 'THIN'
}

export enum UserAboutDrinkEnum {
  Never = 'NEVER',
  Sometime = 'SOMETIME',
  Usually = 'USUALLY'
}

export enum UserAboutEconomyEnum {
  LotSpare = 'LOT_SPARE',
  NotEnough = 'NOT_ENOUGH',
  SometimeNo = 'SOMETIME_NO',
  SomeSpare = 'SOME_SPARE',
  Sufficient = 'SUFFICIENT',
  TooMuch = 'TOO_MUCH',
  Urgent = 'URGENT'
}

export enum UserAboutHeightEnum {
  Cm150 = 'CM150',
  Cm151 = 'CM151',
  Cm156 = 'CM156',
  Cm161 = 'CM161',
  Cm166 = 'CM166',
  Cm171 = 'CM171',
  Cm181 = 'CM181',
  Cm191 = 'CM191',
  Cm200 = 'CM200'
}

export enum UserAboutSmokeEnum {
  Always = 'ALWAYS',
  Never = 'NEVER',
  Sometime = 'SOMETIME'
}

export enum UserAboutWaistEnum {
  In19 = 'IN19',
  In20 = 'IN20',
  In23 = 'IN23',
  In26 = 'IN26',
  In29 = 'IN29',
  In32 = 'IN32',
  In36 = 'IN36',
  In41 = 'IN41',
  In51 = 'IN51',
  In60 = 'IN60'
}

export type UserAdminRemark = {
  __typename?: 'UserAdminRemark';
  images?: Maybe<Array<UserAdminRemarkImage>>;
  message?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserAdminRemarkImage = {
  __typename?: 'UserAdminRemarkImage';
  id: Scalars['Int'];
  imageUrl: Scalars['String'];
};

export enum UserContactPrivacyEnum {
  AllPaid = 'ALL_PAID',
  NoOne = 'NO_ONE',
  OnlyPremium = 'ONLY_PREMIUM'
}

export enum UserDateExpenseEnum {
  AssumeAll = 'ASSUME_ALL',
  DoesntMatter = 'DOESNT_MATTER',
  ExpectOther = 'EXPECT_OTHER',
  FemalePay = 'FEMALE_PAY',
  MalePay = 'MALE_PAY',
  Split = 'SPLIT'
}

export enum UserDrinkingHabitEnum {
  Never = 'NEVER',
  Sometime = 'SOMETIME',
  TryingToQuit = 'TRYING_TO_QUIT',
  Usually = 'USUALLY'
}

export enum UserFavStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum UserFriendStatusEnum {
  Accepted = 'ACCEPTED',
  ByQuota = 'BY_QUOTA',
  Cancelled = 'CANCELLED',
  None = 'NONE',
  Rejected = 'REJECTED',
  RemovedByAdmin = 'REMOVED_BY_ADMIN',
  Requested = 'REQUESTED',
  Unrespond = 'UNRESPOND'
}

export type UserLastIp = {
  __typename?: 'UserLastIp';
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  ipv4?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Int'];
};

export enum UserObjectKeyEnum {
  AgeVerify = 'AGE_VERIFY',
  AgeVerifyRejectReason = 'AGE_VERIFY_REJECT_REASON',
  AndroidLogin = 'ANDROID_LOGIN',
  AndroidLogout = 'ANDROID_LOGOUT',
  FirebaseLogin = 'FIREBASE_LOGIN',
  FirebaseLogout = 'FIREBASE_LOGOUT',
  IosLogin = 'IOS_LOGIN',
  IosLogout = 'IOS_LOGOUT',
  VoiceIntroRejectReason = 'VOICE_INTRO_REJECT_REASON'
}

export type UserOnboardData = {
  avatar?: InputMaybe<Scalars['String']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  dob: Scalars['Date'];
  gender?: InputMaybe<GenderEnum>;
  line?: InputMaybe<Scalars['String']>;
  nickname: Scalars['String'];
  telegram?: InputMaybe<Scalars['String']>;
  wechat?: InputMaybe<Scalars['String']>;
  whatsapp?: InputMaybe<Scalars['String']>;
};

export type UserOnboardPayload = {
  __typename?: 'UserOnboardPayload';
  idToken: Scalars['String'];
  user: User;
};

export enum UserRateLevelEnum {
  Normal = 'NORMAL',
  Premium = 'PREMIUM',
  Verified = 'VERIFIED'
}

export enum UserRoleEnum {
  HLvMember = 'H_LV_MEMBER',
  Normal = 'NORMAL',
  Premium = 'PREMIUM',
  Silver = 'SILVER'
}

export enum UserSmokingHabitEnum {
  Always = 'ALWAYS',
  Never = 'NEVER',
  Sometime = 'SOMETIME',
  TryingToQuit = 'TRYING_TO_QUIT'
}

export enum UserStatusEnum {
  Banned = 'BANNED',
  Deleted = 'DELETED',
  NotVerify = 'NOT_VERIFY',
  ProfileCompleted = 'PROFILE_COMPLETED',
  Verified = 'VERIFIED'
}

export type UserUpdateInput = {
  aboutMe?: InputMaybe<Scalars['String']>;
  ageVerify?: InputMaybe<Scalars['Int']>;
  avatar?: InputMaybe<Scalars['String']>;
  avatarBanned?: InputMaybe<Scalars['Boolean']>;
  avatarFull?: InputMaybe<Scalars['String']>;
  avatarHalf?: InputMaybe<Scalars['String']>;
  dateOutline?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['Date']>;
  line?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoFull?: InputMaybe<Scalars['String']>;
  logoHalf?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  telegram?: InputMaybe<Scalars['String']>;
  userStatus?: InputMaybe<PrismaUserStatusEnum>;
  voice?: InputMaybe<Scalars['Int']>;
  wechat?: InputMaybe<Scalars['String']>;
  whatsapp?: InputMaybe<Scalars['String']>;
};

export enum UserVerifyEnum {
  Accepted = 'ACCEPTED',
  Empty = 'EMPTY',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  RemovedByAdmin = 'REMOVED_BY_ADMIN',
  RemovedByUser = 'REMOVED_BY_USER'
}

export type UserWhereUniqueInput = {
  id: Scalars['Int'];
};

export type ViewsWhereInput = {
  targetUserId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export enum WineReturnStatusEnum {
  Accepted = 'ACCEPTED',
  Deleted = 'DELETED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type SearchUserFragment = { __typename?: 'SearchUser', id: number, gender: GenderEnum, nickname?: string | null, dob?: any | null, city?: string | null, avatar?: string | null, avatarBanned: boolean, ageVerify: UserVerifyEnum, role: UserRoleEnum, status: UserStatusEnum, rateLevel: UserRateLevelEnum, contactPrivacy?: UserContactPrivacyEnum | null, lastSeen: any, voice: UserVerifyEnum, createdAt: any, updatedAt: any, minFrdWine?: number | null, charmValue?: number | null };

export type SubscriptionPlanOverviewFragment = { __typename?: 'PrincessSubscriptionPlan', role: UserRoleEnum, amount: number, activeDays: number, isPrincess: boolean };

export type UserFragment = { __typename?: 'PrincessUser', id: number, nickname?: string | null, logo?: string | null, avatar?: string | null, avatarBanned: boolean, city?: string | null, dob?: any | null, gender: GenderEnum, role: UserRoleEnum, status: UserStatusEnum, rateLevel: UserRateLevelEnum, voice: UserVerifyEnum, ageVerify: UserVerifyEnum };

export type ActivateSubscriptionRebillMutationVariables = Exact<{ [key: string]: never; }>;


export type ActivateSubscriptionRebillMutation = { __typename?: 'Mutation', activateSubscriptionRebill?: { __typename?: 'PrincessSubscription', id: number } | null };

export type ReportUserMutationVariables = Exact<{
  data: ReportUserInputArgs;
}>;


export type ReportUserMutation = { __typename?: 'Mutation', reportUser?: number | null };

export type UpdateUserAvatarMutationVariables = Exact<{
  avatar?: InputMaybe<Scalars['String']>;
}>;


export type UpdateUserAvatarMutation = { __typename?: 'Mutation', updateUserAvatar?: { __typename?: 'User', id: number } | null };

export type UserAcceptTermsMutationVariables = Exact<{ [key: string]: never; }>;


export type UserAcceptTermsMutation = { __typename?: 'Mutation', userAcceptTerms?: { __typename?: 'User', id: number } | null };

export type UserOnboardMutationVariables = Exact<{
  data: UserOnboardData;
}>;


export type UserOnboardMutation = { __typename?: 'Mutation', userOnboard: { __typename?: 'UserOnboardPayload', idToken: string } };

export type GetBlacklistReocrdsQueryVariables = Exact<{
  includeBlacklisted?: InputMaybe<Scalars['Boolean']>;
  includeBlacklistedBy?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetBlacklistReocrdsQuery = { __typename?: 'Query', getBlacklistReocrds?: { __typename?: 'GetBlacklistReocrdsPayload', blacklistedUserIds?: Array<number> | null, blacklistedByUserIds?: Array<number> | null } | null };

export type CollectMonthlyNfmqMutationVariables = Exact<{ [key: string]: never; }>;


export type CollectMonthlyNfmqMutation = { __typename?: 'Mutation', collectMonthlyNfmq?: boolean | null };

export type FriendCountQueryVariables = Exact<{ [key: string]: never; }>;


export type FriendCountQuery = { __typename?: 'Query', friendCount: number };

export type FriendsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type FriendsQuery = { __typename?: 'Query', friends: Array<{ __typename?: 'PrincessUser', id: number, nickname?: string | null, logo?: string | null, avatar?: string | null, avatarBanned: boolean, city?: string | null, dob?: any | null, gender: GenderEnum, role: UserRoleEnum, status: UserStatusEnum, rateLevel: UserRateLevelEnum, voice: UserVerifyEnum, ageVerify: UserVerifyEnum }> };

export type HasIssuedNfmqThisMonthQueryVariables = Exact<{ [key: string]: never; }>;


export type HasIssuedNfmqThisMonthQuery = { __typename?: 'Query', hasIssuedNfmqThisMonth?: { __typename?: 'GetHasIssuedNfmqThisMonthPayload', issued: boolean } | null };

export type SearchUsersQueryVariables = Exact<{
  where: SearchUsersWhereInput;
  take: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type SearchUsersQuery = { __typename?: 'Query', searchUsers: { __typename?: 'SearchUsersPayload', count: number, users: Array<{ __typename?: 'SearchUser', id: number, gender: GenderEnum, nickname?: string | null, dob?: any | null, city?: string | null, avatar?: string | null, avatarBanned: boolean, ageVerify: UserVerifyEnum, role: UserRoleEnum, status: UserStatusEnum, rateLevel: UserRateLevelEnum, contactPrivacy?: UserContactPrivacyEnum | null, lastSeen: any, voice: UserVerifyEnum, createdAt: any, updatedAt: any, minFrdWine?: number | null, charmValue?: number | null }> } };

export type ViewCountQueryVariables = Exact<{
  where: ViewsWhereInput;
}>;


export type ViewCountQuery = { __typename?: 'Query', viewCount: number };

export type ViewsQueryVariables = Exact<{
  where: ViewsWhereInput;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type ViewsQuery = { __typename?: 'Query', views: Array<{ __typename?: 'PrincessUser', id: number, nickname?: string | null, logo?: string | null, avatar?: string | null, avatarBanned: boolean, city?: string | null, dob?: any | null, gender: GenderEnum, role: UserRoleEnum, status: UserStatusEnum, rateLevel: UserRateLevelEnum, voice: UserVerifyEnum, ageVerify: UserVerifyEnum }> };

export const SearchUserFragmentDoc = `
    fragment SearchUser on SearchUser {
  id
  gender
  nickname
  dob
  city
  avatar
  avatarBanned
  ageVerify
  role
  status
  rateLevel
  contactPrivacy
  lastSeen
  voice
  createdAt
  updatedAt
  minFrdWine
  charmValue
}
    `;
export const SubscriptionPlanOverviewFragmentDoc = `
    fragment SubscriptionPlanOverview on PrincessSubscriptionPlan {
  role
  amount
  activeDays
  isPrincess
}
    `;
export const UserFragmentDoc = `
    fragment User on PrincessUser {
  id
  nickname
  logo
  avatar
  avatarBanned
  city
  dob
  gender
  role
  status
  rateLevel
  voice
  ageVerify
}
    `;
export const ActivateSubscriptionRebillDocument = `
    mutation activateSubscriptionRebill {
  activateSubscriptionRebill {
    id
  }
}
    `;
export const useActivateSubscriptionRebillMutation = <
      TError = ClientError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ActivateSubscriptionRebillMutation, TError, ActivateSubscriptionRebillMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ActivateSubscriptionRebillMutation, TError, ActivateSubscriptionRebillMutationVariables, TContext>(
      ['activateSubscriptionRebill'],
      (variables?: ActivateSubscriptionRebillMutationVariables) => fetcher<ActivateSubscriptionRebillMutation, ActivateSubscriptionRebillMutationVariables>(client, ActivateSubscriptionRebillDocument, variables, headers)(),
      options
    );
export const ReportUserDocument = `
    mutation reportUser($data: ReportUserInputArgs!) {
  reportUser(data: $data)
}
    `;
export const useReportUserMutation = <
      TError = ClientError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ReportUserMutation, TError, ReportUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ReportUserMutation, TError, ReportUserMutationVariables, TContext>(
      ['reportUser'],
      (variables?: ReportUserMutationVariables) => fetcher<ReportUserMutation, ReportUserMutationVariables>(client, ReportUserDocument, variables, headers)(),
      options
    );
export const UpdateUserAvatarDocument = `
    mutation updateUserAvatar($avatar: String) {
  updateUserAvatar(avatar: $avatar) {
    id
  }
}
    `;
export const useUpdateUserAvatarMutation = <
      TError = ClientError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateUserAvatarMutation, TError, UpdateUserAvatarMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateUserAvatarMutation, TError, UpdateUserAvatarMutationVariables, TContext>(
      ['updateUserAvatar'],
      (variables?: UpdateUserAvatarMutationVariables) => fetcher<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables>(client, UpdateUserAvatarDocument, variables, headers)(),
      options
    );
export const UserAcceptTermsDocument = `
    mutation userAcceptTerms {
  userAcceptTerms {
    id
  }
}
    `;
export const useUserAcceptTermsMutation = <
      TError = ClientError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UserAcceptTermsMutation, TError, UserAcceptTermsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UserAcceptTermsMutation, TError, UserAcceptTermsMutationVariables, TContext>(
      ['userAcceptTerms'],
      (variables?: UserAcceptTermsMutationVariables) => fetcher<UserAcceptTermsMutation, UserAcceptTermsMutationVariables>(client, UserAcceptTermsDocument, variables, headers)(),
      options
    );
export const UserOnboardDocument = `
    mutation userOnboard($data: UserOnboardData!) {
  userOnboard(data: $data) {
    idToken
  }
}
    `;
export const useUserOnboardMutation = <
      TError = ClientError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UserOnboardMutation, TError, UserOnboardMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UserOnboardMutation, TError, UserOnboardMutationVariables, TContext>(
      ['userOnboard'],
      (variables?: UserOnboardMutationVariables) => fetcher<UserOnboardMutation, UserOnboardMutationVariables>(client, UserOnboardDocument, variables, headers)(),
      options
    );
export const GetBlacklistReocrdsDocument = `
    query getBlacklistReocrds($includeBlacklisted: Boolean = false, $includeBlacklistedBy: Boolean = false) {
  getBlacklistReocrds {
    blacklistedUserIds @include(if: $includeBlacklisted)
    blacklistedByUserIds @include(if: $includeBlacklistedBy)
  }
}
    `;
export const useGetBlacklistReocrdsQuery = <
      TData = GetBlacklistReocrdsQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables?: GetBlacklistReocrdsQueryVariables,
      options?: UseQueryOptions<GetBlacklistReocrdsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetBlacklistReocrdsQuery, TError, TData>(
      variables === undefined ? ['getBlacklistReocrds'] : ['getBlacklistReocrds', variables],
      fetcher<GetBlacklistReocrdsQuery, GetBlacklistReocrdsQueryVariables>(client, GetBlacklistReocrdsDocument, variables, headers),
      options
    );

useGetBlacklistReocrdsQuery.getKey = (variables?: GetBlacklistReocrdsQueryVariables) => variables === undefined ? ['getBlacklistReocrds'] : ['getBlacklistReocrds', variables];
;

export const useInfiniteGetBlacklistReocrdsQuery = <
      TData = GetBlacklistReocrdsQuery,
      TError = ClientError
    >(
      pageParamKey: keyof GetBlacklistReocrdsQueryVariables,
      client: GraphQLClient,
      variables?: GetBlacklistReocrdsQueryVariables,
      options?: UseInfiniteQueryOptions<GetBlacklistReocrdsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetBlacklistReocrdsQuery, TError, TData>(
      variables === undefined ? ['getBlacklistReocrds.infinite'] : ['getBlacklistReocrds.infinite', variables],
      (metaData) => fetcher<GetBlacklistReocrdsQuery, GetBlacklistReocrdsQueryVariables>(client, GetBlacklistReocrdsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetBlacklistReocrdsQuery.getKey = (variables?: GetBlacklistReocrdsQueryVariables) => variables === undefined ? ['getBlacklistReocrds.infinite'] : ['getBlacklistReocrds.infinite', variables];
;

export const CollectMonthlyNfmqDocument = `
    mutation collectMonthlyNfmq {
  collectMonthlyNfmq
}
    `;
export const useCollectMonthlyNfmqMutation = <
      TError = ClientError,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CollectMonthlyNfmqMutation, TError, CollectMonthlyNfmqMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CollectMonthlyNfmqMutation, TError, CollectMonthlyNfmqMutationVariables, TContext>(
      ['collectMonthlyNfmq'],
      (variables?: CollectMonthlyNfmqMutationVariables) => fetcher<CollectMonthlyNfmqMutation, CollectMonthlyNfmqMutationVariables>(client, CollectMonthlyNfmqDocument, variables, headers)(),
      options
    );
export const FriendCountDocument = `
    query friendCount {
  friendCount
}
    `;
export const useFriendCountQuery = <
      TData = FriendCountQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables?: FriendCountQueryVariables,
      options?: UseQueryOptions<FriendCountQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<FriendCountQuery, TError, TData>(
      variables === undefined ? ['friendCount'] : ['friendCount', variables],
      fetcher<FriendCountQuery, FriendCountQueryVariables>(client, FriendCountDocument, variables, headers),
      options
    );

useFriendCountQuery.getKey = (variables?: FriendCountQueryVariables) => variables === undefined ? ['friendCount'] : ['friendCount', variables];
;

export const useInfiniteFriendCountQuery = <
      TData = FriendCountQuery,
      TError = ClientError
    >(
      pageParamKey: keyof FriendCountQueryVariables,
      client: GraphQLClient,
      variables?: FriendCountQueryVariables,
      options?: UseInfiniteQueryOptions<FriendCountQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<FriendCountQuery, TError, TData>(
      variables === undefined ? ['friendCount.infinite'] : ['friendCount.infinite', variables],
      (metaData) => fetcher<FriendCountQuery, FriendCountQueryVariables>(client, FriendCountDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteFriendCountQuery.getKey = (variables?: FriendCountQueryVariables) => variables === undefined ? ['friendCount.infinite'] : ['friendCount.infinite', variables];
;

export const FriendsDocument = `
    query friends($skip: Int, $take: Int) {
  friends(skip: $skip, take: $take) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const useFriendsQuery = <
      TData = FriendsQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables?: FriendsQueryVariables,
      options?: UseQueryOptions<FriendsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<FriendsQuery, TError, TData>(
      variables === undefined ? ['friends'] : ['friends', variables],
      fetcher<FriendsQuery, FriendsQueryVariables>(client, FriendsDocument, variables, headers),
      options
    );

useFriendsQuery.getKey = (variables?: FriendsQueryVariables) => variables === undefined ? ['friends'] : ['friends', variables];
;

export const useInfiniteFriendsQuery = <
      TData = FriendsQuery,
      TError = ClientError
    >(
      pageParamKey: keyof FriendsQueryVariables,
      client: GraphQLClient,
      variables?: FriendsQueryVariables,
      options?: UseInfiniteQueryOptions<FriendsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<FriendsQuery, TError, TData>(
      variables === undefined ? ['friends.infinite'] : ['friends.infinite', variables],
      (metaData) => fetcher<FriendsQuery, FriendsQueryVariables>(client, FriendsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteFriendsQuery.getKey = (variables?: FriendsQueryVariables) => variables === undefined ? ['friends.infinite'] : ['friends.infinite', variables];
;

export const HasIssuedNfmqThisMonthDocument = `
    query hasIssuedNfmqThisMonth {
  hasIssuedNfmqThisMonth {
    issued
  }
}
    `;
export const useHasIssuedNfmqThisMonthQuery = <
      TData = HasIssuedNfmqThisMonthQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables?: HasIssuedNfmqThisMonthQueryVariables,
      options?: UseQueryOptions<HasIssuedNfmqThisMonthQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<HasIssuedNfmqThisMonthQuery, TError, TData>(
      variables === undefined ? ['hasIssuedNfmqThisMonth'] : ['hasIssuedNfmqThisMonth', variables],
      fetcher<HasIssuedNfmqThisMonthQuery, HasIssuedNfmqThisMonthQueryVariables>(client, HasIssuedNfmqThisMonthDocument, variables, headers),
      options
    );

useHasIssuedNfmqThisMonthQuery.getKey = (variables?: HasIssuedNfmqThisMonthQueryVariables) => variables === undefined ? ['hasIssuedNfmqThisMonth'] : ['hasIssuedNfmqThisMonth', variables];
;

export const useInfiniteHasIssuedNfmqThisMonthQuery = <
      TData = HasIssuedNfmqThisMonthQuery,
      TError = ClientError
    >(
      pageParamKey: keyof HasIssuedNfmqThisMonthQueryVariables,
      client: GraphQLClient,
      variables?: HasIssuedNfmqThisMonthQueryVariables,
      options?: UseInfiniteQueryOptions<HasIssuedNfmqThisMonthQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<HasIssuedNfmqThisMonthQuery, TError, TData>(
      variables === undefined ? ['hasIssuedNfmqThisMonth.infinite'] : ['hasIssuedNfmqThisMonth.infinite', variables],
      (metaData) => fetcher<HasIssuedNfmqThisMonthQuery, HasIssuedNfmqThisMonthQueryVariables>(client, HasIssuedNfmqThisMonthDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteHasIssuedNfmqThisMonthQuery.getKey = (variables?: HasIssuedNfmqThisMonthQueryVariables) => variables === undefined ? ['hasIssuedNfmqThisMonth.infinite'] : ['hasIssuedNfmqThisMonth.infinite', variables];
;

export const SearchUsersDocument = `
    query searchUsers($where: SearchUsersWhereInput!, $take: Int!, $skip: Int) {
  searchUsers(where: $where, take: $take, skip: $skip) {
    users {
      ...SearchUser
    }
    count
  }
}
    ${SearchUserFragmentDoc}`;
export const useSearchUsersQuery = <
      TData = SearchUsersQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables: SearchUsersQueryVariables,
      options?: UseQueryOptions<SearchUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SearchUsersQuery, TError, TData>(
      ['searchUsers', variables],
      fetcher<SearchUsersQuery, SearchUsersQueryVariables>(client, SearchUsersDocument, variables, headers),
      options
    );

useSearchUsersQuery.getKey = (variables: SearchUsersQueryVariables) => ['searchUsers', variables];
;

export const useInfiniteSearchUsersQuery = <
      TData = SearchUsersQuery,
      TError = ClientError
    >(
      pageParamKey: keyof SearchUsersQueryVariables,
      client: GraphQLClient,
      variables: SearchUsersQueryVariables,
      options?: UseInfiniteQueryOptions<SearchUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<SearchUsersQuery, TError, TData>(
      ['searchUsers.infinite', variables],
      (metaData) => fetcher<SearchUsersQuery, SearchUsersQueryVariables>(client, SearchUsersDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteSearchUsersQuery.getKey = (variables: SearchUsersQueryVariables) => ['searchUsers.infinite', variables];
;

export const ViewCountDocument = `
    query viewCount($where: ViewsWhereInput!) {
  viewCount(where: $where)
}
    `;
export const useViewCountQuery = <
      TData = ViewCountQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables: ViewCountQueryVariables,
      options?: UseQueryOptions<ViewCountQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ViewCountQuery, TError, TData>(
      ['viewCount', variables],
      fetcher<ViewCountQuery, ViewCountQueryVariables>(client, ViewCountDocument, variables, headers),
      options
    );

useViewCountQuery.getKey = (variables: ViewCountQueryVariables) => ['viewCount', variables];
;

export const useInfiniteViewCountQuery = <
      TData = ViewCountQuery,
      TError = ClientError
    >(
      pageParamKey: keyof ViewCountQueryVariables,
      client: GraphQLClient,
      variables: ViewCountQueryVariables,
      options?: UseInfiniteQueryOptions<ViewCountQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<ViewCountQuery, TError, TData>(
      ['viewCount.infinite', variables],
      (metaData) => fetcher<ViewCountQuery, ViewCountQueryVariables>(client, ViewCountDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteViewCountQuery.getKey = (variables: ViewCountQueryVariables) => ['viewCount.infinite', variables];
;

export const ViewsDocument = `
    query views($where: ViewsWhereInput!, $take: Int, $skip: Int) {
  views(where: $where, take: $take, skip: $skip) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const useViewsQuery = <
      TData = ViewsQuery,
      TError = ClientError
    >(
      client: GraphQLClient,
      variables: ViewsQueryVariables,
      options?: UseQueryOptions<ViewsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ViewsQuery, TError, TData>(
      ['views', variables],
      fetcher<ViewsQuery, ViewsQueryVariables>(client, ViewsDocument, variables, headers),
      options
    );

useViewsQuery.getKey = (variables: ViewsQueryVariables) => ['views', variables];
;

export const useInfiniteViewsQuery = <
      TData = ViewsQuery,
      TError = ClientError
    >(
      pageParamKey: keyof ViewsQueryVariables,
      client: GraphQLClient,
      variables: ViewsQueryVariables,
      options?: UseInfiniteQueryOptions<ViewsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<ViewsQuery, TError, TData>(
      ['views.infinite', variables],
      (metaData) => fetcher<ViewsQuery, ViewsQueryVariables>(client, ViewsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteViewsQuery.getKey = (variables: ViewsQueryVariables) => ['views.infinite', variables];
;
