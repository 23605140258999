import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Dialog";
import { Trans, useTranslation } from "react-i18next";
import { IoShieldSharp } from "react-icons/io5";
import TemporaryBannedIcon from "public/assets/icons/temporary_banned.svg";
import { Text } from "@/modules/client/components/typography/Text";
import Link from "next/link";
import { Route } from "@/consts/Route";
import { format } from "date-fns";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { Button } from "@/components/Button";
import { useBannedUserDialogStore } from "@/features/auth/components/store/bannedUserDialogStore";

export const UserBannedDialog = () => {
  const { t } = useTranslation(["auth", "common"]);
  const { bannedUserDialogState, closeBannedUserDialog } =
    useBannedUserDialogStore();

  const isTempBanned = !!bannedUserDialogState?.bannedUntil;

  return (
    <Dialog
      open={bannedUserDialogState.open}
      onOpenChange={() => closeBannedUserDialog()}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="max-w-sm">
            {isTempBanned
              ? t("banned.account_temp_banned")
              : t("banned.account_perma_banned")}
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-5">
          <div className="text-primary flex items-center justify-center">
            {isTempBanned ? (
              <TemporaryBannedIcon />
            ) : (
              <IoShieldSharp size={24} />
            )}
          </div>
          <Text>
            {isTempBanned ? (
              t("banned.account_temp_banned_caption")
            ) : (
              <Trans i18nKey="auth:banned.account_perma_banned_caption">
                <Link
                  href={Route.Tnc}
                  className="text-primary font-medium underline"
                >
                  {t("common:tnc_privacy.tnc")}
                </Link>
              </Trans>
            )}
          </Text>
          <Text
            className={cn(
              "text-gray-500",
              isTempBanned ? "text-destructive" : "text-gray-700",
            )}
          >
            {isTempBanned && bannedUserDialogState.bannedUntil
              ? t("banned.account_temp_banned_until", {
                  date: format(
                    new Date(bannedUserDialogState.bannedUntil),
                    "yyyy-MM-dd",
                  ),
                })
              : t("banned.account_perma_banned_desc")}
          </Text>
        </div>
        <DialogFooter>
          <Button onClick={() => closeBannedUserDialog()} className="w-full">
            {t("common:action.ok")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
