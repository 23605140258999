// Credit: https://shadcn-calendar-component.vercel.app/

"use client";

import * as React from "react";
import { CalendarIcon, ChevronLeft, ChevronRight } from "lucide-react";
import { startOfMonth, endOfMonth, startOfDay, endOfDay } from "date-fns";
import { toDate, formatInTimeZone } from "date-fns-tz";
import {
  type DateRange,
  type DayPickerSingleProps,
  useNavigation,
} from "react-day-picker";
import { cva, type VariantProps } from "class-variance-authority";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/Popover";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { Button } from "@/components/Button";
import { Calendar } from "@/components/Calendar";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const multiSelectVariants = cva(
  "text-foreground ring-offset-background focus-visible:ring-ring flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border-input bg-background hover:bg-accent hover:text-accent-foreground border",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "text-background hover:bg-accent hover:text-accent-foreground",
        link: "text-background text-primary underline-offset-4 hover:underline",
      },
    },
    defaultVariants: {
      variant: "ghost",
    },
  },
);

interface Props
  extends React.HTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof multiSelectVariants> {
  id?: string;
  className?: string;
  classes?: {
    trigger?: string;
  };
  date: Date | undefined;
  onDateChange: (value: Date) => void;
  closeOnSelect?: boolean;
  placeholder?: string;
  disabled?: boolean;
  dayPickerProps?: Partial<DayPickerSingleProps>;
}

export const DatePicker = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      id = "calendar-date-picker",
      className,
      classes = {},
      closeOnSelect = false,
      onDateChange,
      variant,
      date: value,
      dayPickerProps,
      disabled,
      ...props
    },
    ref,
  ) => {
    const yearsRange = 10;
    const numberOfMonths: number = 1;
    const date = { from: value, to: value };

    const onDateSelect = ({ from, to }: { from: Date; to: Date }) =>
      onDateChange(from);

    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const [monthFrom, setMonthFrom] = React.useState<Date | undefined>(
      dayPickerProps?.toYear
        ? new Date(dayPickerProps?.toYear, new Date().getMonth())
        : (date?.from ?? new Date()),
    );
    const [yearFrom, setYearFrom] = React.useState<number | undefined>(
      dayPickerProps?.toYear ??
        date?.from?.getFullYear() ??
        new Date().getFullYear(),
    );
    const [monthTo, setMonthTo] = React.useState<Date | undefined>(
      numberOfMonths === 2 ? date?.to : date?.from,
    );
    const [yearTo, setYearTo] = React.useState<number | undefined>(
      numberOfMonths === 2
        ? date?.to?.getFullYear()
        : date?.from?.getFullYear(),
    );

    const [highlightedPart, setHighlightedPart] = React.useState<string | null>(
      null,
    );

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const handleClose = () => setIsPopoverOpen(false);

    const handleTogglePopover = () => setIsPopoverOpen((prev) => !prev);

    const handleDateSelect = (range: DateRange | undefined) => {
      if (range) {
        let from = startOfDay(toDate(range.from as Date, { timeZone }));
        let to = range.to ? endOfDay(toDate(range.to, { timeZone })) : from;
        if (numberOfMonths === 1) {
          if (range.from !== date.from) {
            to = from;
          } else {
            from = startOfDay(toDate(range.to as Date, { timeZone }));
          }
        }
        onDateSelect({ from, to });
        setMonthFrom(from);
        setYearFrom(from.getFullYear());
        setMonthTo(to);
        setYearTo(to.getFullYear());
      }
    };

    const handleMonthChange = (newMonthIndex: number, part: string) => {
      if (part === "from") {
        if (yearFrom !== undefined) {
          if (newMonthIndex < 0 || newMonthIndex > yearsRange + 1) return;
          const newMonth = new Date(yearFrom, newMonthIndex, 1);
          const from =
            numberOfMonths === 2
              ? startOfMonth(toDate(newMonth, { timeZone }))
              : date?.from
                ? new Date(
                    date.from.getFullYear(),
                    newMonth.getMonth(),
                    date.from.getDate(),
                  )
                : newMonth;
          const to =
            numberOfMonths === 2
              ? date.to
                ? endOfDay(toDate(date.to, { timeZone }))
                : endOfMonth(toDate(newMonth, { timeZone }))
              : from;
          if (from <= to) {
            onDateSelect({ from, to });
            setMonthFrom(newMonth);
            setMonthTo(date.to);
          }
        }
      } else {
        if (yearTo !== undefined) {
          if (newMonthIndex < 0 || newMonthIndex > yearsRange + 1) return;
          const newMonth = new Date(yearTo, newMonthIndex, 1);
          const from = date.from
            ? startOfDay(toDate(date.from, { timeZone }))
            : startOfMonth(toDate(newMonth, { timeZone }));
          const to =
            numberOfMonths === 2
              ? endOfMonth(toDate(newMonth, { timeZone }))
              : from;
          if (from <= to) {
            onDateSelect({ from, to });
            setMonthTo(newMonth);
            setMonthFrom(date.from);
          }
        }
      }
    };

    const handleYearChange = (newYear: number, part: string) => {
      if (part === "from") {
        if (years.includes(newYear)) {
          const newMonth = monthFrom
            ? new Date(newYear, monthFrom ? monthFrom.getMonth() : 0, 1)
            : new Date(newYear, 0, 1);
          const from =
            numberOfMonths === 2
              ? startOfMonth(toDate(newMonth, { timeZone }))
              : date.from
                ? new Date(newYear, newMonth.getMonth(), date.from.getDate())
                : newMonth;
          const to =
            numberOfMonths === 2
              ? date.to
                ? endOfDay(toDate(date.to, { timeZone }))
                : endOfMonth(toDate(newMonth, { timeZone }))
              : from;
          if (from <= to) {
            onDateSelect({ from, to });
            setYearFrom(newYear);
            setMonthFrom(newMonth);
            setYearTo(date.to?.getFullYear());
            setMonthTo(date.to);
          }
        }
      } else {
        if (years.includes(newYear)) {
          const newMonth = monthTo
            ? new Date(newYear, monthTo.getMonth(), 1)
            : new Date(newYear, 0, 1);
          const from = date.from
            ? startOfDay(toDate(date.from, { timeZone }))
            : startOfMonth(toDate(newMonth, { timeZone }));
          const to =
            numberOfMonths === 2
              ? endOfMonth(toDate(newMonth, { timeZone }))
              : from;
          if (from <= to) {
            onDateSelect({ from, to });
            setYearTo(newYear);
            setMonthTo(newMonth);
            setYearFrom(date.from?.getFullYear());
            setMonthFrom(date.from);
          }
        }
      }
    };

    const today = new Date();

    function getYearsRange(fromYear?: number, toYear?: number) {
      // Default fromYear to 100 years ago if not provided
      const currentYear = today.getFullYear();
      const defaultFromYear = currentYear - 100;
      fromYear = fromYear !== undefined ? fromYear : defaultFromYear;

      // Default toYear to the current year if not provided
      toYear = toYear !== undefined ? toYear : currentYear + yearsRange;

      if (fromYear > toYear) {
        throw new Error("fromYear cannot be greater than toYear");
      }

      // Generate an array of years from fromYear to toYear
      return Array.from(
        { length: toYear - fromYear + 1 },
        (_, i) => fromYear! + i,
      );
    }

    const years = getYearsRange(
      dayPickerProps?.fromYear,
      dayPickerProps?.toYear,
    );

    const handleMouseOver = (part: string) => {
      setHighlightedPart(part);
    };

    const handleMouseLeave = () => {
      setHighlightedPart(null);
    };

    const handleWheel = (event: React.WheelEvent, part: string) => {
      event.preventDefault();
      if (highlightedPart === "firstDay") {
        const newDate = new Date(date.from as Date);
        const increment = event.deltaY > 0 ? -1 : 1;
        newDate.setDate(newDate.getDate() + increment);
        if (newDate <= (date.to as Date)) {
          numberOfMonths === 2
            ? onDateSelect({ from: newDate, to: new Date(date.to as Date) })
            : onDateSelect({ from: newDate, to: newDate });
          setMonthFrom(newDate);
        } else if (newDate > (date.to as Date) && numberOfMonths === 1) {
          onDateSelect({ from: newDate, to: newDate });
          setMonthFrom(newDate);
        }
      } else if (highlightedPart === "firstMonth") {
        const currentMonth = monthFrom ? monthFrom.getMonth() : 0;
        const newMonthIndex = currentMonth + (event.deltaY > 0 ? -1 : 1);
        handleMonthChange(newMonthIndex, "from");
      } else if (highlightedPart === "firstYear" && yearFrom !== undefined) {
        const newYear = yearFrom + (event.deltaY > 0 ? -1 : 1);
        handleYearChange(newYear, "from");
      } else if (highlightedPart === "secondDay") {
        const newDate = new Date(date.to as Date);
        const increment = event.deltaY > 0 ? -1 : 1;
        newDate.setDate(newDate.getDate() + increment);
        if (newDate >= (date.from as Date)) {
          onDateSelect({ from: new Date(date.from as Date), to: newDate });
          setMonthTo(newDate);
        }
      } else if (highlightedPart === "secondMonth") {
        const currentMonth = monthTo ? monthTo.getMonth() : 0;
        const newMonthIndex = currentMonth + (event.deltaY > 0 ? -1 : 1);
        handleMonthChange(newMonthIndex, "to");
      } else if (highlightedPart === "secondYear" && yearTo !== undefined) {
        const newYear = yearTo + (event.deltaY > 0 ? -1 : 1);
        handleYearChange(newYear, "to");
      }
    };

    React.useEffect(() => {
      const firstDayElement = document.getElementById(`firstDay-${id}`);
      const firstMonthElement = document.getElementById(`firstMonth-${id}`);
      const firstYearElement = document.getElementById(`firstYear-${id}`);
      const secondDayElement = document.getElementById(`secondDay-${id}`);
      const secondMonthElement = document.getElementById(`secondMonth-${id}`);
      const secondYearElement = document.getElementById(`secondYear-${id}`);

      const elements = [
        firstDayElement,
        firstMonthElement,
        firstYearElement,
        secondDayElement,
        secondMonthElement,
        secondYearElement,
      ];

      const addPassiveEventListener = (element: HTMLElement | null) => {
        if (element) {
          element.addEventListener(
            "wheel",
            handleWheel as unknown as EventListener,
            {
              passive: false,
            },
          );
        }
      };

      elements.forEach(addPassiveEventListener);

      return () => {
        elements.forEach((element) => {
          if (element) {
            element.removeEventListener(
              "wheel",
              handleWheel as unknown as EventListener,
            );
          }
        });
      };
    }, [highlightedPart, date]);

    const formatWithTz = (date: Date, fmt: string) => {
      return formatInTimeZone(date, timeZone, fmt);
    };

    return (
      <>
        <style>
          {`
            .date-part {
              touch-action: none;
            }
          `}
        </style>
        <Popover
          open={isPopoverOpen}
          onOpenChange={setIsPopoverOpen}
          modal={true}
        >
          <PopoverTrigger asChild>
            <Button
              id="date"
              ref={ref}
              variant={"outline"}
              {...props}
              className={cn(
                "h-auto w-full justify-between px-3 text-left text-base font-normal text-gray-900 dark:text-gray-300",
                // multiSelectVariants({ variant, className }),
                classes.trigger,
              )}
              onClick={handleTogglePopover}
              suppressHydrationWarning
              disabled={disabled}
            >
              <span>
                {date?.from ? (
                  date.to ? (
                    <>
                      <span
                        id={`firstDay-${id}`}
                        className={cn(
                          "date-part",
                          highlightedPart === "firstDay" &&
                            "font-bold underline",
                        )}
                        onMouseOver={() => handleMouseOver("firstDay")}
                        onMouseLeave={handleMouseLeave}
                      >
                        {formatWithTz(date.from, "dd")}
                      </span>{" "}
                      <span
                        id={`firstMonth-${id}`}
                        className={cn(
                          "date-part",
                          highlightedPart === "firstMonth" &&
                            "font-bold underline",
                        )}
                        onMouseOver={() => handleMouseOver("firstMonth")}
                        onMouseLeave={handleMouseLeave}
                      >
                        {formatWithTz(date.from, "LLL")}
                      </span>
                      ,{" "}
                      <span
                        id={`firstYear-${id}`}
                        className={cn(
                          "date-part",
                          highlightedPart === "firstYear" &&
                            "font-bold underline",
                        )}
                        onMouseOver={() => handleMouseOver("firstYear")}
                        onMouseLeave={handleMouseLeave}
                      >
                        {formatWithTz(date.from, "y")}
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        id="day"
                        className={cn(
                          "date-part",
                          highlightedPart === "day" && "font-bold underline",
                        )}
                        onMouseOver={() => handleMouseOver("day")}
                        onMouseLeave={handleMouseLeave}
                      >
                        {formatWithTz(date.from, "dd")}
                      </span>{" "}
                      <span
                        id="month"
                        className={cn(
                          "date-part",
                          highlightedPart === "month" && "font-bold underline",
                        )}
                        onMouseOver={() => handleMouseOver("month")}
                        onMouseLeave={handleMouseLeave}
                      >
                        {formatWithTz(date.from, "LLL")}
                      </span>
                      ,{" "}
                      <span
                        id="year"
                        className={cn(
                          "date-part",
                          highlightedPart === "year" && "font-bold underline",
                        )}
                        onMouseOver={() => handleMouseOver("year")}
                        onMouseLeave={handleMouseLeave}
                      >
                        {formatWithTz(date.from, "y")}
                      </span>
                    </>
                  )
                ) : (
                  <span className="text-gray-500">
                    {props.placeholder ?? "Pick a date"}
                  </span>
                )}
              </span>
              <CalendarIcon className="ml-2 h-4 w-4" />
            </Button>
          </PopoverTrigger>
          {isPopoverOpen && (
            <PopoverContent
              className="w-auto p-2"
              align="start"
              avoidCollisions={false}
              // onInteractOutside={handleClose}
              onEscapeKeyDown={handleClose}
              style={{
                maxHeight: "var(--radix-popover-content-available-height)",
                overflowY: "auto",
              }}
            >
              <div className="flex">
                <div className="flex flex-col">
                  <div className="flex">
                    {/* @ts-ignore */}
                    <Calendar
                      mode="range"
                      defaultMonth={monthFrom}
                      month={monthFrom}
                      onMonthChange={setMonthFrom}
                      selected={date}
                      // @ts-ignore
                      onSelect={handleDateSelect}
                      numberOfMonths={numberOfMonths}
                      showOutsideDays={true}
                      className={cn(className, "px-2")}
                      fromYear={years[0]}
                      toYear={years[years.length - 1]}
                      captionLayout="dropdown-buttons"
                      components={{
                        Caption: function Caption() {
                          const { goToMonth, nextMonth, previousMonth } =
                            useNavigation();

                          return (
                            <div className="flex items-center justify-between gap-2">
                              <div className="flex gap-1">
                                <select
                                  value={
                                    monthFrom
                                      ? months[monthFrom.getMonth()]
                                      : undefined
                                  }
                                  onChange={(e) =>
                                    handleMonthChange(
                                      months.indexOf(e.target.value),
                                      "from",
                                    )
                                  }
                                  className="hover:bg-accent hover:text-accent-foreground w-auto gap-1 border-none py-2 text-sm font-bold focus:ring-0 focus:ring-offset-0"
                                >
                                  {months.map((option, idx) => (
                                    <option key={idx} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>

                                <select
                                  value={
                                    monthFrom
                                      ? monthFrom.getFullYear().toString()
                                      : undefined
                                  }
                                  onChange={(e) =>
                                    handleYearChange(
                                      Number(e.target.value),
                                      "from",
                                    )
                                  }
                                  className="hover:bg-accent hover:text-accent-foreground w-auto gap-1 border-none py-2 text-sm font-bold focus:ring-0 focus:ring-offset-0"
                                >
                                  {years.map((option, idx) => (
                                    <option key={idx} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div>
                                <Button
                                  size="sm"
                                  variant="ghost"
                                  disabled={!previousMonth}
                                  onClick={() =>
                                    previousMonth && goToMonth(previousMonth)
                                  }
                                  className={cn({
                                    "text-gray-500": !previousMonth,
                                  })}
                                >
                                  <ChevronLeft className="h-4 w-4" />
                                </Button>
                                <Button
                                  size="sm"
                                  variant="ghost"
                                  disabled={!nextMonth}
                                  onClick={() =>
                                    nextMonth && goToMonth(nextMonth)
                                  }
                                  className={cn({
                                    "text-gray-500": !nextMonth,
                                  })}
                                >
                                  <ChevronRight className="h-4 w-4" />
                                </Button>
                              </div>
                            </div>
                          );
                        },
                        IconLeft: ({ ...props }) => (
                          <ChevronLeft className="h-4 w-4" />
                        ),
                        IconRight: ({ ...props }) => (
                          <ChevronRight className="h-4 w-4" />
                        ),
                      }}
                      {...dayPickerProps}
                    />
                  </div>
                </div>
              </div>
            </PopoverContent>
          )}
        </Popover>
      </>
    );
  },
);

DatePicker.displayName = "DatePicker";
