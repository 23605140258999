import { Button, type ButtonVariants } from "@/components/Button";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { usePrincessAuthDialogContext } from "@/oldFeatures/tailwind/components/PrincessAuthDialogContext";
import { useTranslation } from "next-i18next";

type LoginButtonProps = {
  className?: string;
  variant?: ButtonVariants["variant"];
};

export function LoginButton({ className, variant }: LoginButtonProps) {
  const { t } = useTranslation("common");
  const { openAuthDialog } = usePrincessAuthDialogContext();

  return (
    <Button
      variant={variant ?? "secondary"}
      className={cn("rounded-full px-6 py-2", className)}
      onClick={() => openAuthDialog("email", "login")}
    >
      {t("action.login")}
    </Button>
  );
}
