import { twc } from "react-twc";
import * as ShadcnSelect from "@/modules/external/shadcn/ui/components/ui/select";

export const Select = ShadcnSelect.Select;

export const SelectGroup = ShadcnSelect.SelectGroup;

export const SelectValue = ShadcnSelect.SelectValue;

export const SelectTrigger = twc(
  ShadcnSelect.SelectTrigger,
)`focus:border-primary h-10 bg-white text-base text-gray-900 focus:ring-0 focus:ring-transparent data-[placeholder]:text-gray-400 dark:text-current`;

export const SelectScrollUpButton = twc(ShadcnSelect.SelectScrollUpButton)``;

export const SelectScrollDownButton = twc(
  ShadcnSelect.SelectScrollDownButton,
)``;

export const SelectContent = twc(ShadcnSelect.SelectContent)`max-h-56`;

export const SelectLabel = twc(ShadcnSelect.SelectLabel)``;

export const SelectItem = twc(
  ShadcnSelect.SelectItem,
)`my-1 text-base text-gray-700`;

export const SelectSeparator = twc(ShadcnSelect.SelectSeparator)``;
