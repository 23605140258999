import { Textarea as ShadcnTextarea } from "@/modules/external/shadcn/ui/components/ui/textarea";
import React, { type ComponentProps } from "react";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import {
  type CountingMethod,
  getLengthByMethod,
} from "@/components/InputLength";

export type TextareaProps = {
  countingMethod?: CountingMethod;
} & ComponentProps<typeof ShadcnTextarea>;

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { countingMethod = "normal", className, onChange, maxLength, ...props },
    ref,
  ) => {
    return (
      <ShadcnTextarea
        className={cn(
          "focus-visible:border-primary min-h-[60px] bg-transparent text-base text-gray-900 shadow-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-transparent dark:text-current",
          className,
        )}
        onChange={(e) => {
          const newValue = e.target.value;
          const prevValue = e.target.dataset.prevValue || "";

          if (maxLength) {
            const prevLength = getLengthByMethod[countingMethod](prevValue);
            const newLength = getLengthByMethod[countingMethod](newValue);

            // If previous value was already at max length, prevent any new input
            if (prevLength >= maxLength && newLength > prevLength) {
              return;
            }

            // If new value exceeds max length, trim it appropriately
            if (newLength > maxLength) {
              if (countingMethod === "unicode_double") {
                while (
                  getLengthByMethod[countingMethod](e.target.value) > maxLength
                ) {
                  e.target.value = e.target.value.slice(0, -1);
                }
              } else {
                e.target.value = e.target.value.substring(0, maxLength);
              }
            }
          }

          e.target.dataset.prevValue = newValue;
          onChange?.(e);
        }}
        ref={ref}
        {...props}
      />
    );
  },
);
Textarea.displayName = "Textarea";
