import { ForgetPasswordForm } from "@/features/auth/components/ForgetPasswordForm";
import { TncAndPrivacyLinks } from "@/features/common/TncAndPrivacyLinks";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { PhoneContinueForm } from "@/oldFeatures/tailwind/components/AuthDialogForms/PhoneContinueForm";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import { UserStatusEnum } from "@princess/graphql-codegen/graphql-hooks";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/Dialog";
import { Trans, useTranslation } from "next-i18next";
import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Text } from "@/modules/client/components/typography/Text";
import { EmailLoginForm } from "@/components/AuthDialogForms/EmailLoginForm";
import { EmailRegisterForm } from "@/components/AuthDialogForms/EmailRegisterForm";
import { Button } from "@/components/Button";
import { UserBannedDialog } from "@/features/auth/components/UserBannedDialog";

type Provider = "phone" | "email";
type AuthAction = "login" | "register" | "forgetPassword";
type AuthDialogState = {
  action: AuthAction;
  open: boolean;
  provider: Provider;
  referrerId?: string;
};

const initialState: AuthDialogState = {
  open: false,
  action: "register",
  provider: "phone",
};

const PrincessAuthDialogContext = createContext<
  | ({
      openAuthDialog: (
        provider: Provider,
        action: AuthAction,
        referrerId?: string,
      ) => void;
      closeAuthDialog: (shouldLogout?: boolean) => void;
      referrerId?: string;
      setTitle: Dispatch<SetStateAction<string | undefined>>;
    } & AuthDialogState)
  | null
>(null);

/**
 * this context control the open and close of the auth dialog
 *
 */
export const usePrincessAuthDialogContext = () => {
  const context = useContext(PrincessAuthDialogContext);

  if (!context)
    throw new Error(
      "usePrincessAuthDialogContext must be used within PrincessAuthDialogProvider",
    );

  return context;
};

export const PrincessAuthDialogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation("common");
  const [state, setState] = useState(initialState);
  const { action, open, provider } = state;
  const { user, logout } = useUserContext();
  // @todo - refactor state management and remove this
  const [title, setTitle] = useState<string | undefined>();

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.status === UserStatusEnum.NotVerify && !open) {
      logout();
      return;
    }
  }, [logout, user, open]);

  const openAuthDialog = (
    provider: Provider,
    action: AuthAction,
    referrerId?: string,
  ) => {
    setState({
      provider,
      action,
      open: true,
      referrerId,
    });
  };

  const closeAuthDialog = (shouldLogout: boolean = false) => {
    if (shouldLogout) logout();
    // Only set open to false to avoid flashing of states
    setState((s) => ({ ...s, open: false, referrerId: undefined }));
  };

  const toggleProvider = () => {
    setState((s) => ({
      ...s,
      provider: provider === "email" ? "phone" : "email",
    }));
  };

  const toggleAction = () => {
    setState((s) => ({
      ...s,
      action: action === "login" ? "register" : "login",
    }));
  };

  const {
    service: { recaptcha },
  } = getPublicConfig();

  const handleOpenChange = useCallback((newIsOpen: boolean) => {
    setState((s) => ({ ...s, open: newIsOpen, referrerId: undefined }));
  }, []);

  const isLoginOrRegister = action === "login" || action === "register";

  return (
    <PrincessAuthDialogContext.Provider
      value={{
        openAuthDialog,
        closeAuthDialog,
        setTitle,
        ...state,
      }}
    >
      {children}
      <Dialog open={open} onOpenChange={handleOpenChange}>
        <DialogContent
          onInteractOutside={(e) => e.preventDefault()}
          onEscapeKeyDown={(e) => e.preventDefault()}
          className="max-h-screen sm:max-w-lg"
        >
          <DialogHeader>
            <DialogTitle>
              {title
                ? title
                : isLoginOrRegister
                  ? t(`auth.header`, {
                      action: t(`auth.${action}`),
                      provider: t(`auth.${provider}`),
                    })
                  : t("home:password.forget_password")}
            </DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <div className="flex flex-col gap-5">
              {provider === "phone" ? (
                <GoogleReCaptchaProvider reCaptchaKey={recaptcha.siteKey}>
                  <PhoneContinueForm />
                </GoogleReCaptchaProvider>
              ) : action === "login" ? (
                <EmailLoginForm />
              ) : action === "register" ? (
                <EmailRegisterForm />
              ) : (
                <ForgetPasswordForm />
              )}
              {isLoginOrRegister ? (
                <>
                  <div className="flex flex-1 flex-col items-center gap-5 self-stretch">
                    <div className="flex items-center justify-center gap-2.5 self-stretch">
                      <div className="flex-1 border-t border-solid border-gray-200"></div>
                      <div className="text-gray-400">
                        {action === "login"
                          ? t("auth.login_alternative_action")
                          : t("auth.sign_up_alternative_action")}
                      </div>
                      <div className="flex-1 border-t border-solid border-gray-200"></div>
                    </div>
                    <div className="flex items-start justify-center gap-5 self-stretch">
                      <Button
                        variant="outline"
                        className="flex flex-shrink-0 flex-grow items-center justify-center gap-2.5 rounded-[100px] border border-gray-300 bg-white px-[9px]"
                        onClick={toggleProvider}
                      >
                        {provider === "phone" ? (
                          <>
                            <MailIcon className="h-5 w-5 text-gray-900" />
                            <Text className="text-gray-900">
                              {t("auth.email")}
                            </Text>
                          </>
                        ) : (
                          <>
                            <PhoneIcon className="h-5 w-5 text-gray-900" />
                            <Text className="text-gray-900">
                              {t("auth.phone")}
                            </Text>
                          </>
                        )}
                      </Button>
                    </div>
                    <div className="flex items-center justify-center gap-1 self-stretch text-center">
                      <Text className="text-gray-700">
                        {action === "login"
                          ? t("auth.prompt_for_sign_up")
                          : t("auth.prompt_for_login")}
                      </Text>
                      <Button
                        variant="ghost"
                        onClick={toggleAction}
                        className="text-primary cursor-pointer p-0 font-medium"
                      >
                        {action === "login"
                          ? t("auth.sign_up_now")
                          : t("auth.login_now")}
                      </Button>
                    </div>
                    <div className="self-stretch text-center">
                      <Text
                        as="p"
                        className="text-center text-sm text-gray-500"
                      >
                        <Trans
                          i18nKey="common:tnc_privacy.acknowledge"
                          components={{
                            tncAndPrivacyComponent: (
                              <TncAndPrivacyLinks textClassName="text-gray-500" />
                            ),
                          }}
                        />
                      </Text>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </DialogDescription>
        </DialogContent>
      </Dialog>
      <UserBannedDialog />
    </PrincessAuthDialogContext.Provider>
  );
};
