import { useRef, useState } from "react";
import { Text } from "@/modules/client/components/typography/Text";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { BsFillStarFill as StarIcon } from "react-icons/bs";

type Props = {
  readonly?: boolean;
  maxRating?: number;
  step?: number;
  value?: number;
  onClick?: (rating: number) => void;
  starClassName?: string;
  showLabel?: boolean;
};

const STAR_COLOR_CLASSNAME = "h-6 w-6 text-[#FBBF24]";
export const RatingStars = ({
  readonly = false,
  maxRating = 5,
  step = 0.5,
  value = 0,
  onClick = () => {},
  starClassName,
  showLabel = false,
}: Props) => {
  const [hoverActiveStar, setHoverActiveStar] = useState(value);
  const [isHovered, setIsHovered] = useState(false);
  const ratingContainerRef = useRef<HTMLButtonElement>(null);

  const calculateRating = (e: React.MouseEvent) => {
    const { width, left } = ratingContainerRef.current!.getBoundingClientRect();
    let percent = (e.clientX - left) / width;
    const numberInStars = Math.max(percent * maxRating, 0);
    const nearestNumber = Math.round((numberInStars + step / 2) / step) * step;
    return Number(
      nearestNumber.toFixed(step.toString().split(".")[1]?.length || 0),
    );
  };

  const handleClick = (e: React.MouseEvent) => {
    setIsHovered(false);
    onClick(calculateRating(e));
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    setIsHovered(true);
    setHoverActiveStar(calculateRating(e));
  };

  const handleMouseLeave = () => {
    setHoverActiveStar(-1); // Reset to default state
    setIsHovered(false);
  };

  return (
    <div className="flex items-center gap-2">
      {showLabel && (
        <Text size="sm">{(Math.floor(value * 10) / 10).toFixed(1)}</Text>
      )}
      <button
        type="button"
        disabled={readonly}
        className={cn(
          "relative inline-flex  text-left",
          // for !readonly: gap-[n] + px-[n/2]
          readonly ? "gap-0.5" : "gap-2 px-1",
        )}
        onClick={handleClick}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        ref={ratingContainerRef}
      >
        {[...new Array(maxRating)].map((_, index) => {
          const activeState = isHovered
            ? hoverActiveStar
            : readonly
              ? value
              : Math.floor(value * 2) / 2;

          const showEmptyIcon = activeState === 0 || activeState < index + 1;

          const isActiveRating = activeState !== 0;
          const isRatingWithPrecision = activeState % 1 !== 0;
          const isRatingEqualToIndex = Math.ceil(activeState) === index + 1;
          const showRatingWithPrecision =
            isActiveRating && isRatingWithPrecision && isRatingEqualToIndex;

          return (
            <div className="relative" key={index}>
              <div
                style={{
                  width: showRatingWithPrecision
                    ? `${(activeState % 1) * 100}%`
                    : "0%",
                }}
                className="absolute overflow-hidden"
              >
                <StarIcon className={cn(STAR_COLOR_CLASSNAME, starClassName)} />
              </div>
              <div>
                {showEmptyIcon ? (
                  <StarIcon
                    className={cn(
                      STAR_COLOR_CLASSNAME,
                      "text-gray-300",
                      starClassName,
                    )}
                  />
                ) : (
                  <StarIcon
                    className={cn(STAR_COLOR_CLASSNAME, starClassName)}
                  />
                )}
              </div>
            </div>
          );
        })}
      </button>
    </div>
  );
};
