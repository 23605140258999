import { TestUserButtonGroup } from "@/components/AuthDialogForms/TestUserButtonGroup";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import { useErrorHandling } from "@/helpers/useErrorHandling";
import {
  type ExtractFormDataType,
  FormBuilder,
} from "@/modules/form-builders/FormBuilder";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { useEmailAuth } from "@/oldFeatures/auth/hooks/useEmailAuth";
import { usePrincessAuthDialogContext } from "@/oldFeatures/tailwind/components/PrincessAuthDialogContext";
import { Button } from "@/components/Button";
import { type ComponentProps, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

const { isProd } = getPublicConfig();

export const EmailLoginForm = () => {
  const { t } = useTranslation("common");
  const { handleGraphqlError } = useErrorHandling();
  const { refetchUser } = useUserContext();
  const { handleEmailLogin } = useEmailAuth();
  const { openAuthDialog, closeAuthDialog } = usePrincessAuthDialogContext();

  const formFields = useMemo(
    () =>
      ({
        email: {
          label: t("auth.email"),
          required: true,
          type: "email",
          placeholder: "",
          validation: z
            .string()
            .min(
              1,
              t("auth.required_error", {
                field: t("auth.email"),
              }),
            )
            .email(t("auth.invalid_email")),
        },
        password: {
          label: t("auth.password"),
          required: true,
          type: "password",
          placeholder: "",
          validation: z
            .string()
            .min(8, t("auth.password_min_length", { length: 8 })),
        },
      }) satisfies ComponentProps<typeof FormBuilder>["fields"],
    [t],
  );

  const formComponents = useMemo<
    ComponentProps<typeof FormBuilder>["components"]
  >(
    () => ({
      button: (props) => <Button {...props} className="flex-1" />,
    }),
    [],
  );

  const onSubmit = useCallback(
    async (data: ExtractFormDataType<typeof formFields>) => {
      try {
        await handleEmailLogin({ email: data.email, password: data.password });
        await refetchUser();
        closeAuthDialog();
      } catch (error: any) {
        if (error instanceof Error) {
          handleGraphqlError({ error });
        }
      }
    },
    [handleGraphqlError, handleEmailLogin, refetchUser, closeAuthDialog],
  );

  return (
    <div className="flex flex-col gap-5">
      <FormBuilder
        layout={["email", "password"]}
        fields={formFields}
        onSubmit={onSubmit}
        actionButtonLabel={t("auth.login")}
        components={formComponents}
        submitOnValid={true}
        footer={
          <Button
            className="text-primary-500 -mb-2.5 ml-auto block p-0"
            variant="ghost"
            type="button"
            size="sm"
            onClick={() => {
              openAuthDialog("email", "forgetPassword");
            }}
          >
            {t("home:main.forget_password")}
          </Button>
        }
        renderActionRow={(props) => (
          <div {...props} className="flex justify-center">
            {props.children}
          </div>
        )}
      />
      {!isProd && <TestUserButtonGroup onSubmit={onSubmit} />}
    </div>
  );
};
