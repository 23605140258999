import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { type ComponentType } from "react";
import { type InputHTMLAttributes, type ReactNode, forwardRef } from "react";

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  helperText?: ReactNode;
  hasError?: boolean;
  /**
   * An svg icon that accept classname as prop
   */
  leftIcon?: ComponentType<any>;
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const {
      className,
      label,
      helperText,
      hasError,
      leftIcon: LeftIcon,
      ...inputProps
    } = props;
    return (
      <div className={cn(className, "text-left", "relative")}>
        {label && (
          <label
            htmlFor={inputProps.id}
            className="mb-1 block text-sm font-normal text-gray-500"
          >
            {label}
          </label>
        )}
        {LeftIcon && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-sm ">
            <LeftIcon className="!text-base text-gray-500" />
          </div>
        )}
        <input
          type="text"
          ref={ref}
          {...inputProps}
          className={cn("form-text-input", !!LeftIcon && "pl-8")}
        />

        <span
          className={cn("text-xs", hasError ? "text-red-500" : "text-gray-400")}
        >
          {helperText}
        </span>
      </div>
    );
  },
);
