import { create } from "zustand";

type DialogState = {
  open: boolean;
  bannedUntil: Date | null;
};

interface State {
  bannedUserDialogState: DialogState;
}

interface Actions {
  closeBannedUserDialog: () => void;
  openBannedUserDialog: (bannedUntil: Date | null) => void;
}

export const useBannedUserDialogStore = create<State & Actions>((set) => ({
  bannedUserDialogState: {
    open: false,
    bannedUntil: null,
  },
  closeBannedUserDialog: () =>
    set({ bannedUserDialogState: { open: false, bannedUntil: null } }),
  openBannedUserDialog: (bannedUntil: Date | null) =>
    set({ bannedUserDialogState: { open: true, bannedUntil } }),
}));
