import { Button } from "@/components/Button";
import { type ButtonProps } from "@/modules/external/shadcn/ui/components/ui/button";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import Image, { type ImageProps } from "next/image";
import React, { type ElementType, forwardRef, type SVGProps } from "react";

const iconButtonVariants = cva("", {
  variants: {
    size: {
      xs: "h-4 w-4 p-1",
      sm: "h-5 w-5 p-1.5",
      default: "h-6 w-6 p-2",
      lg: "h-7 w-7 p-1.5",
    },
  },
  defaultVariants: {
    size: "default",
  },
});

type SvgIconButtonProps = {
  Icon: ElementType<SVGProps<SVGSVGElement>>;
  classes?: {
    button?: string;
    icon?: string;
  };
} & VariantProps<typeof iconButtonVariants> &
  Omit<ButtonProps, "size" | "className">;
export const SvgIconButton = forwardRef<HTMLButtonElement, SvgIconButtonProps>(
  (
    { Icon, disabled = false, size, classes, variant = "ghost", ...props },
    ref,
  ) => {
    return (
      <Button
        ref={ref}
        disabled={disabled}
        variant={variant}
        className={cn(
          "box-content rounded-full p-1",
          iconButtonVariants({
            size,
          }),
          classes?.button,
        )}
        {...props}
      >
        <Icon
          className={cn(
            iconButtonVariants({
              size,
            }),
            classes?.icon,
            "p-0",
          )}
        />
      </Button>
    );
  },
);
SvgIconButton.displayName = "SvgIconButton";

type ImageIconButtonProps = {
  classes?: { button?: string; image?: string };
} & Omit<ImageProps, "className"> &
  VariantProps<typeof iconButtonVariants> &
  Omit<ButtonProps, "size">;

export const ImageIconButton = forwardRef<
  HTMLButtonElement,
  ImageIconButtonProps
>(({ alt, size, classes, disabled, loading, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      disabled={disabled}
      className={cn(
        "box-content overflow-hidden rounded-full p-1",
        iconButtonVariants({
          size,
        }),
        classes?.button,
      )}
      {...props}
    >
      <Image
        alt={alt}
        className={cn(
          iconButtonVariants({
            size,
          }),
          classes?.image,
          "p-0",
        )}
        width={0}
        height={0}
        unoptimized
        {...props}
      />
    </Button>
  );
});
ImageIconButton.displayName = "ImageIconButton";
