import { useTranslation } from "next-i18next";
import { IoNotifications } from "react-icons/io5";
import { Text } from "@/modules/client/components/typography/Text";

export const NoNotificationDisplay = () => {
  const { t } = useTranslation("notification");

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <IoNotifications className="h-28 w-28 text-gray-200" />
      <Text className="text-gray-400">{t("no_notification")}</Text>
    </div>
  );
};
