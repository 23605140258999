import { Logo } from "@/featuresPrincess/layout/Logo";
import { HeaderShadow } from "@/features/layout/components/HeaderShadow";
import { type ReactNode } from "react";
import { cn } from "@/modules/external/shadcn/ui/lib/utils";

type NavContainerProps = {
  children: ReactNode;
  className?: string;
};

export const NavContainer = ({ children, className }: NavContainerProps) => {
  return (
    <nav className={cn("header border-none", className)}>
      <div className="relative mx-auto flex max-w-7xl items-center justify-between gap-6 px-6 py-[5px] sm:py-3">
        <Logo />
        {children}
      </div>
      <HeaderShadow />
    </nav>
  );
};
