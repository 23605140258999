import { cn } from "@/modules/external/shadcn/ui/lib/utils";
import { Checkbox } from "@/components/Checkbox";
import React from "react";

type Props<T extends string> = {
  className?: string;
  label: string;
  options: Array<{
    label: string;
    value: T;
    checked: boolean;
  }>;
  onChange: (values: Array<T>) => void;
  disabled?: boolean;
};

export const CheckboxGroup = <T extends string>(props: Props<T>) => {
  const isCheckedAll = props.options.every((option) => option.checked);
  const isIntermediate =
    props.options.some((option) => option.checked) && !isCheckedAll;

  const currentSelected = props.options
    .filter((option) => option.checked)
    .map((option) => option.value);

  return (
    <div>
      <div className="mb-3 flex items-center space-x-2">
        <Checkbox
          className={cn("border-primary", props.className)}
          id={props.label}
          checked={isIntermediate ? "indeterminate" : isCheckedAll}
          onCheckedChange={(checked) => {
            return !checked
              ? props.onChange([])
              : props.onChange(props.options.map((option) => option.value));
          }}
          disabled={props.disabled}
        />
        <label
          htmlFor={props.label}
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {props.label}
        </label>
      </div>
      <div style={{ marginLeft: "28px" }}>
        {props.options.map((option) => (
          <div className="mb-2 flex items-center space-x-2" key={option.value}>
            <Checkbox
              className={cn("border-primary", props.className)}
              id={option.value}
              checked={option.checked}
              onCheckedChange={(checked) =>
                checked
                  ? props.onChange([
                      ...new Set([...currentSelected, option.value]),
                    ])
                  : props.onChange(
                      currentSelected.filter((c) => c !== option.value),
                    )
              }
            />
            <label
              key={option.value}
              htmlFor={option.value}
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
